import { redirect } from "react-router-dom";

import { CRMMainLayout } from "../../pages/layout/crm";

import {
  OrderPage,
  CustomerPage,
  ClientsPage,
  SuppliersPage,
  ProductAllPage,
  ProductOnePage,
  SettingsPage,
  SettingsOrderPage,
  SettingsSourcesPage,
  SettingsCommunicationPage,
  SettingsFinancePage,
  SettingsRolesPage,
  SettingsAccountStatusPage,
  AnalyticsProductsPage,
  AnalyticsOrderPage,
  AnalyticsManagersPage,
  AnalyticsSuppliersPage,
  ProductsTemplateAllPage,
  ProductsTemplateOnePage,
  BalancePage,
  UnloadingAllPage,
  UnloadingOnePage,
  StockAllPage,
  StockOnePage,
  ReferenceBookPage,
} from "../../pages";

import { CreateProduct, EditProduct } from "../../pages/catalog/products";
import { CreateUnloading, EditUnloading } from "pages/catalog/unloading";
import { ProductTemplateLayout } from "../../pages/catalog";
import {
  MultipleActionsBook,
  MultipleActionsValues,
  TableBooks,
  TableValues,
} from "components/catalog/reference-book";

const routes = (crm: string) => [
  {
    path: "orders",
    element: <OrderPage />,
  },
  {
    path: "customers",
    element: <CustomerPage />,
  },
  {
    path: "clients",
    element: <ClientsPage />,
  },
  {
    path: "suppliers",
    element: <SuppliersPage />,
  },
  {
    path: "catalog",
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath =
        process.env.NODE_ENV === "development" ? `/${crm}/catalog` : "/catalog";

      if (pathname === rootPath || pathname === rootPath + "/") {
        return redirect(
          rootPath +
            "/" +
            (window.localStorage.getItem("categoryCatalogPage") || "0") +
            "/?limit=" +
            (window.localStorage.getItem("limitСatalogPage") || "20") +
            "&offset=" +
            (window.localStorage.getItem("offsetСatalogPage") || "0") +
            (window.localStorage.getItem("searchСatalogPage")
              ? "&search=" + window.localStorage.getItem("searchСatalogPage")
              : ""),
        );
      }

      return null;
    },
    children: [
      {
        path: ":categoryId",
        element: <ProductAllPage />,
      },
    ],
  },
  {
    path: "catalog/:categoryId/product/:productId",
    element: (
      <EditProduct>
        <ProductOnePage />
      </EditProduct>
    ),
  },
  {
    path: "catalog/:categoryId/product/:productId/variation/:variationId",
    element: (
      <EditProduct>
        <ProductOnePage />
      </EditProduct>
    ),
  },
  {
    path: "catalog/:categoryId/product/add",
    element: (
      <CreateProduct>
        <ProductOnePage />
      </CreateProduct>
    ),
  },
  {
    path: "products-template",
    element: <ProductTemplateLayout />,
    children: [
      {
        index: true,
        element: <ProductsTemplateAllPage />,
      },
      {
        path: ":id",
        element: <ProductsTemplateOnePage />,
      },
    ],
  },
  {
    path: "reference-book",
    element: <ReferenceBookPage />,
    children: [
      {
        path: ":group",
        element: (
          <>
            <TableBooks />
            <MultipleActionsBook />
          </>
        ),
      },
      {
        path: ":group/values/:book",
        element: (
          <>
            <TableValues />
            <MultipleActionsValues />
          </>
        ),
      },
    ],
  },
  {
    path: "settings",
    element: <SettingsPage />,
  },
  {
    path: "settings/order",
    element: <SettingsOrderPage />,
  },
  {
    path: "settings/sources",
    element: <SettingsSourcesPage />,
  },
  {
    path: "settings/communication",
    element: <SettingsCommunicationPage />,
  },
  {
    path: "settings/finance",
    element: <SettingsFinancePage />,
  },
  {
    path: "settings/roles",
    element: <SettingsRolesPage />,
  },
  {
    path: "settings/account-status",
    element: <SettingsAccountStatusPage />,
  },
  {
    path: "analytics/products",
    element: <AnalyticsProductsPage />,
  },
  {
    path: "analytics/order",
    element: <AnalyticsOrderPage />,
  },
  {
    path: "analytics/managers",
    element: <AnalyticsManagersPage />,
  },
  {
    path: "analytics/suppliers",
    element: <AnalyticsSuppliersPage />,
  },
  {
    path: "balance",
    element: <BalancePage />,
  },
  {
    path: "unloading/:marketplaceId",
    element: <UnloadingAllPage />,
  },
  {
    path: "unloading/:marketplaceId/link/:unloadingId",
    element: (
      <EditUnloading>
        <UnloadingOnePage />
      </EditUnloading>
    ),
  },
  {
    path: "unloading/:marketplaceId/link/add",
    element: (
      <CreateUnloading>
        <UnloadingOnePage />
      </CreateUnloading>
    ),
  },
  {
    path: "stock",
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath =
        process.env.NODE_ENV === "development" ? `/${crm}/stock` : "/stock";

      if (pathname === rootPath || pathname === rootPath + "/") {
        return redirect(
          rootPath +
            "/" +
            (window.localStorage.getItem("categoryStockPage") || "0") +
            "/?limit=" +
            (window.localStorage.getItem("limitStockPage") || "20") +
            "&offset=" +
            (window.localStorage.getItem("offsetStockPage") || "0") +
            (window.localStorage.getItem("searchStockPage")
              ? "&search=" + window.localStorage.getItem("searchStockPage")
              : ""),
        );
      }

      return null;
    },
    children: [
      {
        path: ":categoryId",
        element: <StockAllPage />,
      },
    ],
  },
  {
    path: "stock/:categoryId/product/:productId",
    element: <StockOnePage />,
  },
];

export const CRMMainRouterConfig = (crm: string) => [
  {
    path: "*",
    loader: ({ request }: { request: any }) => {
      const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

      return redirect(rootPath + "/catalog");
    },
  },
  {
    path: process.env.NODE_ENV === "development" ? "/:crm/" : "/",
    element: <CRMMainLayout />,
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

      if (pathname === rootPath || pathname === rootPath + "/") {
        return redirect(rootPath + "/catalog");
      }

      return null;
    },
    children: routes(crm),
  },
];
