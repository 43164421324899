import { AllBooksCheckbox } from "./components";
import style from "./TableBooksHead.module.scss";

export function TableBooksHead() {
  return (
    <div className={style["head"]}>
      <AllBooksCheckbox />
      <div className={style["label"]}>Довідники</div>
    </div>
  );
}
