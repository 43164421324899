import { Link, useParams, useSearchParams } from "react-router-dom";

import style from "./TopPanel.module.scss";
import { Button, Input, SearchInputProps } from "@ui";

export function TopPanel() {
  const { categoryId } = useParams();
  const [_, setQueryParams] = useSearchParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  return (
    <div className={style["topPanel"]}>
      <div className={style["search"]}>
        <Input<SearchInputProps>
          inputType="search"
          placeholder="Пошук..."
          onSearch={(value) => {
            setQueryParams((params) =>
              Object.assign(
                {
                  limit: params.get("limit") || "20",
                  offset: params.get("offset") || "0",
                },
                value && {
                  search: value,
                },
              ),
            );
          }}
        />
      </div>
      <div></div>
    </div>
  );
}
