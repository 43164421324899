import Tenant from "@interfaces/tenant";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import cn from "classnames";

import style from "./Item.module.scss";
import { showModal } from "@global-state/entity/modal-state/slice";
import { tenant_delete_setData } from "@global-state/pages/tenant/slice";

export function Item({ data }: { data: Tenant }) {
  const dispatch = useAppDispatch();

  return (
    <tr>
      <td className={style["col"]}>
        <p className={style["subdomain"]}>{data.subdomain}</p>
        <div className={style["fullLinkBox"]}>
          <div
            className={cn(
              style["customOverflow"],
              "overflow-gradient",
              "custom-responsive-domain",
            )}
          ></div>
          <div className="custom-responsive-domain overflow-hidden">
            <p className={style["fullLink"]}>{data.subdomain}.levelup.com.ua</p>
          </div>
          <div className={style["deleteButtonBox"]}>
            <Button
              type="delete"
              onClick={() => {
                dispatch(tenant_delete_setData(data));
                dispatch(
                  showModal({
                    name: "DeleteTenantModal",
                  }),
                );
              }}
            />
          </div>
        </div>
        <p className={style["balanceMobile"]}>
          <span className={style["label"]}>Баланс: </span>
          <span
            className={cn(
              style["balanceValue"],
              data.balance > 0 ? "text-[#39A827]" : "text-[#D03A3A]",
            )}
          >
            $ {data.balance}
          </span>
        </p>
      </td>
      <td className={cn(style["col"], "max-md:hidden")}>
        <p className={style["CRMType"]}>
          {data.account_type === "droper" ? "Дропшипер" : null}
          {data.account_type === "supplier" ? "Постачальник" : null}
        </p>
      </td>
      <td className={cn(style["col"], "max-md:hidden")}>
        <p
          className={cn(
            style["balance"],
            data.balance > 0 ? "text-[#39A827]" : "text-[#D03A3A]",
          )}
        >
          $ {data.balance}
        </p>
      </td>
      <td className={style["col"]}>
        <div className={style["CRMTypeMobile"]}>
          <p className={style["label"]}>Тип</p>
          <p className={style["CRMTypeValue"]}>
            {data.account_type === "droper" ? "Дропшипер" : null}
            {data.account_type === "supplier" ? "Постачальник" : null}
          </p>
        </div>
        <a
          className={style["loginLink"]}
          href={
            process.env.NODE_ENV === "development"
              ? `http://localhost:3000/${data.subdomain}`
              : `https://${data.subdomain}.dev.levelup.com.ua`
          }
          target="blank"
          rel="noreferrer"
        >
          Вхід
        </a>
      </td>
    </tr>
  );
}
