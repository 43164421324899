import { useParams } from "react-router-dom";
import { AllValuesCheckbox } from "./components";
import style from "./TableValuesHead.module.scss";
import { useReferenceBookValueAllQuery } from "@RTKQuery/reference-book-values/api";

export function TableValuesHead() {
  const { book } = useParams();
  const { data } = useReferenceBookValueAllQuery(Number(book || "-1"));

  return (
    <div className={style["head"]}>
      <AllValuesCheckbox values={data} />
      <div className={style["label"]}>Значення UA</div>
      <div className={style["label"]}>Значення RU</div>
      <div></div>
    </div>
  );
}
