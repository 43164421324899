import { createContext, useEffect, useState } from "react";
import { ListVariations } from "./components";
import { SelectableParameter } from "@interfaces/product";
import { useAppDispatch } from "store/hooks";
import { setCombinationsSelected } from "@global-state/pages/product/slice";

export const variationsContext = createContext<{
  data: SelectableParameter[];
  switchChekedParameter: (
    id: SelectableParameter["id"],
    status: boolean,
  ) => void;
  switchChekedParameterValue: (
    id: SelectableParameter["id"],
    idValue: SelectableParameter["values"][0]["id"],
    status: boolean,
  ) => void;
} | null>(null);

export function Core({ data }: { data: SelectableParameter[] }) {
  const [parameters, setParameters] = useState<SelectableParameter[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setParameters(data);
  }, [data]);

  useEffect(() => {
    dispatch(
      setCombinationsSelected(
        parameters
          .map((obj) => ({
            name: obj.name,
            values: obj.values
              .filter((value) => value.selected)
              .map((value) => value.name),
          }))
          .filter((obj) => obj.values.length > 0),
      ),
    );
  }, [parameters]);

  return (
    <variationsContext.Provider
      value={{
        data: parameters,
        switchChekedParameter(id, status) {
          setParameters((state) =>
            state.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  selected: status,
                  values: item.values.map((value) => ({
                    ...value,
                    selected: status,
                  })),
                };
              } else {
                return item;
              }
            }),
          );
        },
        switchChekedParameterValue(id, idValue, status) {
          let checkedValue = 0;

          setParameters((state) =>
            state.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  values: item.values.map((value) => {
                    if (value.selected) {
                      checkedValue++;
                    }

                    if (value.id === idValue) {
                      if (status) {
                        checkedValue++;
                      } else {
                        checkedValue--;
                      }

                      return {
                        ...value,
                        selected: status,
                      };
                    } else {
                      return value;
                    }
                  }),
                  selected: item.values.length === checkedValue,
                };
              } else {
                return item;
              }
            }),
          );
        },
      }}
    >
      <ListVariations data={parameters} />
    </variationsContext.Provider>
  );
}
