import style from "./ProvidersItemFooterWrapper.module.scss";

export function ProvidersItemFooterWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <div className={style["wrapper"]}>
      <p className={style["label"]}>Без співпадіння</p>
      {children}
    </div>
  );
}
