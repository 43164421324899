import { ProductCheckbox } from "./components";
import cn from "classnames";
import style from "./TableProductsItem.module.scss";
import { ProductHoroshop } from "@interfaces/unloading";

export function TableProductsItem({ data }: { data: ProductHoroshop }) {
  return (
    <div className={style["item"]}>
      <div
        className={cn(style["bodyColumn"], "justify-self-start pl-[10px]")}
        title={data.ua_name}
      >
        <p className="max-w-full truncate">{data.ua_name}</p>
      </div>
      <div className={cn(style["bodyColumn"])} title={data.sku}>
        <p className="max-w-full truncate">{data.sku}</p>
      </div>
      <div className={style["bodyColumn"]}>
        <div className={style["imageLimit"]}>
          <img src={data.picture} alt={data.ua_name} className={style["img"]} />
        </div>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.buying_price)}
      >
        <p className="max-w-full truncate">{data.buying_price} грн.</p>
      </div>
      <div
        className={cn(style["bodyColumn"], "justify-self-start")}
        title={String(data.categories?.join(", "))}
      >
        <p className="line-clamp-2">{data.categories?.join(", ")}</p>
      </div>
      <div className={style["bodyColumn"]}>
        <ProductCheckbox product={data} />
      </div>
    </div>
  );
}
