import ReferenceBookGroup, { ReferenceBook } from "@interfaces/refence-book";
import { useReferenceBookAllQuery } from "@RTKQuery/reference-book/api";
import { useParams } from "react-router-dom";
import style from "./TableBooksBody.module.scss";
import { Spinner } from "@ui";

export function TableBooksBody({
  children,
}: {
  children: (book: ReferenceBook, group: ReferenceBookGroup) => JSX.Element;
}) {
  const { group } = useParams();

  const { data, isFetching, isError } = useReferenceBookAllQuery();

  const groupItem: ReferenceBookGroup | undefined = data?.find((item) => {
    return item.id === Number(group || "-1");
  });

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!groupItem || groupItem.items.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодного довідника.</p>
      </div>
    );
  } else {
    return <>{groupItem.items.map((item) => children(item, groupItem))}</>;
  }
}
