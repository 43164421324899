import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import ProductsTemplate, {
  CharacteristicValues,
  VariationValues,
} from "../interfaces/products-template";
import ReferenceBookGroup, {
  ReferenceBook,
  ReferenceBookValue,
} from "../interfaces/refence-book";
import {
  ProductChildren,
  ProductExtended,
  ProductTable,
  ProductVariations,
} from "../interfaces/product";
import { Pagination } from "../interfaces/shared";
import Category from "../interfaces/category";
import { HoroshopCategory, MarketplaceId, ProductHoroshop, UnloadingExtended, UnloadingLink } from "@interfaces/unloading";
import { Balance, BalanceHistoryRecord } from "@interfaces/balance";
import { clearCRMUserAll } from "@global-state/entity/CRMUser/slice";
import { ProductStock, ConnectedProduct, ProductProvider } from "@interfaces/stock";

const rootPath =
  process.env.NODE_ENV === "development"
    ? window.location.pathname.split("/")[1] || "account"
    : window.location.host.split(".")[0];

const baseQuery = fetchBaseQuery({
  baseUrl: `https://${rootPath}.api.levelup.com.ua/dropers`,
  prepareHeaders: (headers) => {
    const accessToken = window.localStorage.getItem("access_token_crm");
    if (accessToken) {
      headers.set("authorization", accessToken);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refresh = fetchBaseQuery({
      baseUrl: `https://${rootPath}.api.levelup.com.ua/dropers`,
      prepareHeaders: (headers) => {
        const accessToken = window.localStorage.getItem("refresh_token_crm");
        if (accessToken) {
          headers.set("authorization", accessToken);
        }
        return headers;
      },
    });

    const refreshResult = await refresh(
      {
        url: "/auth/refresh_token",
        method: "POST",
      },
      api,
      extraOptions,
    );
    if (refreshResult.data) {
      const { access_token } = refreshResult.data as { access_token: string };

      window.localStorage.setItem("access_token_crm", access_token);

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(clearCRMUserAll());
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Product", "ProductHoroshop", "UnloadingList"],
  endpoints: (build) => ({
    referenceBookAll: build.query<ReferenceBookGroup[], void>({
      query: () => "dictionary",
    }),
    referenceBookValueAll: build.query<ReferenceBookValue[], ReferenceBook["id"]>({
      query: (idBook) => `dictionary/values/${idBook}`,
    }),

    productsTemplateAll: build.query<Pick<ProductsTemplate, "id" | "name">[], void>({
      query: () => "patterns",
    }),
    productsTemplateOne: build.query<ProductsTemplate, ProductsTemplate["id"]>({
      query: (idTemplate) => `patterns/${idTemplate}`,
    }),
    productsTemplateOneVariationValues: build.query<VariationValues, ProductsTemplate["id"]>({
      query: (idTemplate) => `variations/${idTemplate}`,
    }),
    productsTemplateOneCharacteristicValues: build.query<CharacteristicValues, ProductsTemplate["id"]>({
      query: (idTemplate) => `chars/${idTemplate}`,
    }),

    productsAll: build.query<Pagination<{ products: ProductTable[] }>, {
      category_id: ProductExtended["category_id"];
      offset: Pagination<void>["offset"];
      limit: Pagination<void>["limit"];
      search?: string;
    }>({
      query: ({ category_id, limit, offset, search }) =>
        `category/${category_id}/products?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""}`,
      providesTags: (result, error, page) =>
        result
          ? [
            {
              type: "Product" as const,
              id: page.category_id + "|" + page.limit + "|" + page.offset,
            },
            { type: "Product", id: "PRODUCT-LIST" },
          ]
          : [{ type: "Product", id: "PRODUCT-LIST" }],
    }),
    productsOne: build.query<ProductChildren<ProductExtended> & ProductVariations, ProductExtended["id"]>({
      query: (idProduct) => `products/${idProduct}`,
      providesTags: ["Product"],
    }),

    categoriesAll: build.query<Category[], void>({
      query: () => "category",
    }),

    unloadingAll: build.query<UnloadingLink[], MarketplaceId>({
      query: (id) => `unloadings/list?marketplace_id=${id}`,
      providesTags: ["UnloadingList"],
    }),
    unloadingOne: build.query<UnloadingExtended, UnloadingLink["id"]>({
      query: (id) => `unloadings/horoshop/${id}`,
      transformResponse: (response) => {
        const data = response as UnloadingExtended;

        data.formula_markup = 
        data.formula_markup ? 
        data.formula_markup.map(item => ({
          ...item,
          id: crypto.randomUUID()
        })) : null

        return data;
      }
    }),
    productsAllHoroshop: build.query<Pagination<{ products: ProductHoroshop[] }>, {
      categoriesIds: Category["id"][];
      offset: Pagination<void>["offset"];
      limit: Pagination<void>["limit"];
      search?: string;
    }>({
      query: ({ categoriesIds, limit, offset, search }) => {
        let listItem = categoriesIds.map((id) => `categories=${id}`).join("&");
        listItem = listItem || "categories=-1";

        return `unloadings/horoshop/products?${listItem}&limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""}`
      },
      providesTags: (result, error, page) =>
        result
          ? [
            {
              type: "ProductHoroshop" as const,
              id: page.categoriesIds.join("|") + "|" + page.limit + "|" + page.offset,
            },
            { type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" },
          ]
          : [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
    }),
    horoshopCategoriesAll: build.query<HoroshopCategory[], string | void>({
      query: (search) => "horoshop/categories/history" + (search ? `?search=${search}` : ""),
    }),

    balance: build.query<{
      ok: boolean,
      balance: Balance
    }, void>({
      query: () => "balance",
    }),
    balanceHistory: build.query<BalanceHistoryRecord[], void>({
      query: () => "balance/history"
    }),

    productStockOne: build.query<ProductChildren<ProductStock>, ProductTable["id"]>({
      query: (productId) => "stock/" + productId
    }),
    productStockOneConnectedProducts: build.query<ConnectedProduct[], ProductTable["id"]>({
      query: (productId) => "stock/" + productId + "/easydrop"
    }),
    productsProviders: build.query<Pagination<{ products: ProductProvider[] }>, string | void>({
      query: (search) => "stock/easydrop/products" + (search ? `?search=${search}` : "")
    })
  }),
});
