import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "store/store";
import { StockPageData } from "./interface";

const initialState: StockPageData = {
    deleteProductProviderId: -1,
    addProductProviderSelectData: {
        product_id: -1,
        supplier_id: -1
    },
    searchTextProductProvider: ""
}

const stockSlice = createSlice({
    initialState,
    name: "stockPage",
    reducers: {
        setDeleteProductProviderId: (state, action: SliceAction<StockPageData["deleteProductProviderId"]>) => {
            state.deleteProductProviderId = action.payload;
        },
        clearDeleteProductProviderId: (state) => {
            state.deleteProductProviderId = -1;
        },

        setAddProductProviderSelectData: (state, action: SliceAction<StockPageData["addProductProviderSelectData"]>) => {
            state.addProductProviderSelectData = action.payload;
        },
        clearAddProductProviderSelectData: (state) => {
            state.addProductProviderSelectData = {
                product_id: -1,
                supplier_id: -1
            };
        },

        setSearchTextProductProvider: (state, action: SliceAction<StockPageData["searchTextProductProvider"]>) => {
            state.searchTextProductProvider = action.payload;
        },

        clearAllStockData: (state) => {
            state.deleteProductProviderId = -1;
            state.addProductProviderSelectData = {
                product_id: -1,
                supplier_id: -1
            };
        }
    }
})

const { reducer, actions } = stockSlice;

export default reducer;

export const {
    setDeleteProductProviderId,
    clearDeleteProductProviderId,

    setAddProductProviderSelectData,
    clearAddProductProviderSelectData,

    setSearchTextProductProvider,

    clearAllStockData
} = actions;