import ProductsTemplate from "@interfaces/products-template";
import { useProductsTemplateAllQuery } from "@RTKQuery/products-template/api";
import style from "./TableTemplateBody.module.scss";
import { Spinner } from "@ui";

export function TableTemplateBody({
  children,
}: {
  children: (template: Pick<ProductsTemplate, "id" | "name">) => JSX.Element;
}) {
  const { data, isFetching, isError } = useProductsTemplateAllQuery();

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодного товару.</p>
      </div>
    );
  } else {
    return <>{data.map((item) => children(item))}</>;
  }
}
