import { SpoilerButton } from "@ui";
import style from "./MenuCategoriesLabel.module.scss";

export function MenuCategoriesLabel({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      <div className={style["label"]}>Категорії</div>
      <div
        className={style["mobileLabel"]}
        onClick={() => setIsOpen((state) => !state)}
      >
        <p>Групи довідників</p>
        <SpoilerButton
          isOpen={isOpen}
          className={style["customSpoilerButton"]}
        />
      </div>
    </>
  );
}
