import { useEffect } from "react";
import { allFiles } from "../ProductImages/ProductImages";
import { useNavigate, useParams } from "react-router-dom";
import {
  useProductCreateMutation,
  useProductUpdateMutation,
} from "@RTKQuery/products/api";
import { useAppSelector } from "store/hooks";
import { SubmitButton } from "@ui";

export function ProductSubmitButton({
  basicFormRef,
}: {
  basicFormRef: React.RefObject<HTMLButtonElement>;
}) {
  const { productId, variationId } = useParams();

  const [productCreate, createStatus] = useProductCreateMutation({
    fixedCacheKey: "product-create",
  });

  const [productUpdate, updateStatus] = useProductUpdateMutation({
    fixedCacheKey: "product-edit",
  });

  const {
    basicInfo: { data, isValid },
    pictures,
    combinationsSelectedVariationValues: combinations,
    characteristics,
  } = useAppSelector((state) => state.productPage);

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  const navigate = useNavigate();

  useEffect(() => {
    if (createStatus.isSuccess) {
      navigate(
        crm +
          `/catalog/${createStatus.data.product.category_id}/product/${createStatus.data.product.id}`,
      );
    }

    if (updateStatus.isSuccess) {
      navigate(
        crm +
          `/catalog/${updateStatus.data.product.category_id}/product/${updateStatus.data.product[variationId ? "parent_id" : "id"]}${variationId ? `/variation/${variationId}` : ""}`,
      );
    }

    const timer = setTimeout(() => {
      createStatus.reset();
      updateStatus.reset();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [
    createStatus.isSuccess,
    createStatus.isError,
    updateStatus.isSuccess,
    updateStatus.isError,
  ]);

  return (
    <SubmitButton
      className="max-sm:rounded-[6px] max-sm:text-[12px] "
      type="button"
      hFull
      disabled={createStatus.isLoading || updateStatus.isLoading}
      isUninitialized={
        createStatus.isUninitialized && updateStatus.isUninitialized
      }
      isLoading={createStatus.isLoading || updateStatus.isLoading}
      isSuccess={createStatus.isSuccess || updateStatus.isSuccess}
      isError={createStatus.isError || updateStatus.isError}
      uninitializedText="Зберегти"
      successText="Успішно збережено"
      errorText="Не вдалось зберегти"
      errorMesage={
        (createStatus.error
          ? (createStatus.error as unknown as { data: { error: string } }).data
              .error
          : "") ||
        (updateStatus.error
          ? (
              updateStatus.error as unknown as {
                data: { error: string };
              }
            ).data.error
          : "")
      }
      onClick={() => {
        basicFormRef.current?.click();

        if (!isValid) {
          return null;
        }

        if (data) {
          if (!productId) {
            productCreate({
              payload: {
                product: {
                  ...data,
                  sku:
                    data.sku +
                    (combinations[0].length
                      ? "-" +
                        combinations[0].map((elem) => elem.value).join("-")
                      : ""),
                  variations: combinations[0],
                  characteristics,
                },
                children: combinations.slice(1).map((item) => ({
                  ...data,
                  sku:
                    data.sku + "-" + item.map((elem) => elem.value).join("-"),
                  variations: item,
                  characteristics,
                })),
              },
              pictures: {
                files: allFiles,
                positions: pictures.map((item) => item.id),
              },
            });
          } else {
            productUpdate({
              payload: {
                id: variationId ? Number(variationId) : Number(productId),
                parent_id: variationId ? Number(productId) : null,
                ...data,
                characteristics,
              },
              pictures: {
                files: allFiles,
                positions: pictures.map((item) => item.id),
              },
            });
          }
        }
      }}
    />
  );
}
