import { Button } from "@ui";
import style from "./EditableValue.module.scss";
import { useEffect, useState } from "react";
import { SmallSaveButton } from "components/common/UI/buttons/SmallSaveButton/SmallSaveButton";
import { useStockUpdateMutation } from "@RTKQuery/stock/api";
import { useParams } from "react-router-dom";
import { ProductStock } from "@interfaces/stock";

export function EditableValue({ data }: { data: ProductStock }) {
  const { productId } = useParams();

  const [stockUpdate, { isError, isLoading, isSuccess, reset }] =
    useStockUpdateMutation();

  const [editMod, setEditMod] = useState<boolean>(false);
  const [editModValue, setEditModValue] = useState<string>("");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isError) {
        reset();
      }
      if (isSuccess) {
        setEditMod(false);
      }
    }, 2500);

    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess, isError]);

  if (!editMod) {
    return (
      <div className={style["interactive_off"]}>
        <span>{data.qty + " шт"}</span>
        <Button
          type="edit"
          onClick={() => {
            setEditMod(true);
          }}
        />
      </div>
    );
  } else {
    return (
      <div className={style["interactive_on"]}>
        <input
          className={style["input"]}
          type="text"
          value={editModValue}
          placeholder={data.qty + " шт"}
          onChange={(e) => {
            setEditModValue(e.target.value.replace(/\D/g, ""));
          }}
        />
        <div className="relative z-[3]">
          <SmallSaveButton
            status={
              (isLoading && "load") ||
              (isError && "error") ||
              (isSuccess && "success") ||
              "idle"
            }
            onClick={() => {
              stockUpdate({
                parentId: Number(productId),
                id: data.id,
                qty: Number(editModValue),
              });
            }}
          />
        </div>
      </div>
    );
  }
}
