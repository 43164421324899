import { EditParameterForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  parameters_delete_deleteData,
  parameters_edit_clearData,
} from "@global-state/pages/productsTemplate/slice";

import { useCharacteristicsUpdateMutation } from "@RTKQuery/characteristics/api";
import { useVariationsUpdateMutation } from "@RTKQuery/variations/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "EditParameterModal";

export function EditParameterModal() {
  const [
    _,
    { isSuccess: isSuccessCharacteristic, reset: resetCharacteristic },
  ] = useCharacteristicsUpdateMutation({
    fixedCacheKey: "edit-characteristic",
  });

  const [__, { isSuccess: isSuccessVariation, reset: resetVariation }] =
    useVariationsUpdateMutation({
      fixedCacheKey: "edit-variation",
    });

  const { modalName: activeModal, mode: activeMode } = useAppSelector(
    (state) => state.modalState,
  );

  const edit_parameters = useAppSelector(
    (state) => state.productsTemplatePage.parameters.edit,
  );

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccessCharacteristic || isSuccessVariation) {
      if (!activeModal) {
        dispatch(parameters_edit_clearData());
        dispatch(
          parameters_delete_deleteData({
            data: {
              id: edit_parameters?.id || -1,
              singleOnly: false,
            },
            parameterType:
              activeMode === "characteristic" ? "characteristic" : "variation",
          }),
        );

        resetCharacteristic();
        resetVariation();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccessCharacteristic, isSuccessVariation, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccessCharacteristic && !isSuccessVariation) {
      dispatch(parameters_edit_clearData());
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Відредагувати параметр"
    >
      <EditParameterForm />
    </CRMModalLayout>
  );
}
