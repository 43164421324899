import {
  MultipleActionsTemplate,
  TableTemplate,
} from "../../../../components/catalog/products-template";

import style from "./ProductsTemplateAllPage.module.scss";

export function ProductsTemplateAllPage() {
  return (
    <div className={style["page"]}>
      <TableTemplate />
      <MultipleActionsTemplate />
    </div>
  );
}
