import { useState } from "react";
import {
  TableProductHead,
  TableProductWrapper,
  TableProductBody,
  TableProductItem,
} from "./components";

export function TableProduct() {
  const [sortBy, setSortBy] = useState<
    | "buying_price/asc"
    | "buying_price/desc"
    | "retail_price/asc"
    | "retail_price/desc"
    | "ua_name/desc"
    | "ua_name/asc"
    | ""
  >("");

  return (
    <TableProductWrapper>
      <>
        <TableProductHead sortBy={sortBy} setSortBy={setSortBy} />
        <TableProductBody
          sortBy={sortBy.split("/")[0]}
          order={sortBy.split("/")[1]}
        >
          {(data) => <TableProductItem key={data.id} data={data} />}
        </TableProductBody>
      </>
    </TableProductWrapper>
  );
}
