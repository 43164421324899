import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useReferenceBookValueCreateMutation } from "@RTKQuery/reference-book-values/api";
import { Button, SubmitButton, Input } from "@ui";

const ValidationSchema = Yup.object().shape({
  friends: Yup.array().of(
    Yup.object().shape({
      nameUA: Yup.string()
        .max(36, "Максимальна довжина рядка 36 символів")
        .required("Назва UA - обов'язкове поле"),
      nameRU: Yup.string()
        .max(36, "Максимальна довжина рядка 36 символів")
        .required("Назва RU - обов'язкове поле"),
    }),
  ),
});

export function AddBookValueForm() {
  const [
    createDictionaryValue,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useReferenceBookValueCreateMutation({
    fixedCacheKey: "add-book-value",
  });

  const { book } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        friends: [
          {
            nameUA: "",
            nameRU: "",
          },
        ],
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        createDictionaryValue({
          idBook: Number(book),
          listNames: values.friends.map((item) => ({
            name: {
              ua: item.nameUA,
              ru: item.nameRU,
            },
          })),
        })
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => {});
      }}
    >
      {({ values }) => (
        <Form>
          <FieldArray name="friends">
            {({ insert, remove, push }) => (
              <>
                <div className="scroll max-h-[300px] overflow-auto pr-[10px]">
                  {values.friends.length > 0 &&
                    values.friends.map((friend, index) => (
                      <div
                        key={index}
                        className="mb-[10px] flex gap-[15px] max-sm:mb-[30px]"
                      >
                        <div className="flex w-full gap-[15px] max-sm:flex-col max-sm:gap-[5px]">
                          <div className="w-[50%] max-sm:w-[100%]">
                            <Input
                              formikName={`friends.${index}.nameUA`}
                              label="Назва UA"
                              inputType="text"
                            />
                          </div>
                          <div className="w-[50%] max-sm:w-[100%]">
                            <Input
                              formikName={`friends.${index}.nameRU`}
                              label="Назва RU"
                              inputType="text"
                            />
                          </div>
                        </div>
                        <div className="pt-[25px] max-sm:my-auto max-sm:pt-0">
                          <Button
                            type="delete"
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <div className="mb-[40px] flex justify-center pr-[35px]">
                  <Button
                    type="add"
                    onClick={() => {
                      push({ nameUA: "", nameRU: "" });
                    }}
                  />
                </div>
                <SubmitButton
                  type="submit"
                  disabled={isLoading}
                  isUninitialized={isUninitialized}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isError={isError}
                  uninitializedText="Зберегти"
                  successText="Успішно збережено"
                  errorText="Не вдалось зберегти"
                  errorMesage={
                    error
                      ? (error as unknown as { data: { error: string } }).data
                          .error
                      : ""
                  }
                />
              </>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}
