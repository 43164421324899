import { hideModal } from "@global-state/entity/modal-state/slice";
import { clearBalanceTopUpValue } from "@global-state/pages/balance/slice";
import { useTopUpBalanceMutation } from "@RTKQuery/balance/api";
import { SubmitButton } from "@ui";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import cn from "classnames";
import style from "./PaymentMethodModal.module.scss";

const MODAL_NAME = "PaymentMethodModal";

export function PaymentMethodModal() {
  const [
    topUpBalance,
    { isLoading, isError, isUninitialized, isSuccess, reset, error },
  ] = useTopUpBalanceMutation();

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const balanceTopUpValue = useAppSelector(
    (state) => state.balancePage.balance,
  );

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal) {
        dispatch(clearBalanceTopUpValue());
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess) {
      dispatch(clearBalanceTopUpValue());
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Вибрати спосіб оплати"
    >
      <>
        <div className={style["methods"]}>
          <div className={cn(style["method"], true && style["method_active"])}>
            <img src="/images/payment/mono.png" alt="mono" />
          </div>
          <div className={cn(style["method"], true && style["method_disable"])}>
            <img src="/images/payment/private.png" alt="private" />
          </div>
        </div>
        <div className="mx-auto w-[225px] max-sm:w-full">
          <SubmitButton
            type="button"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Продовжити"
            successText="Успіх"
            errorText="Сталась помилка"
            errorMesage={
              error
                ? (
                    error as unknown as {
                      data: { error: string };
                    }
                  ).data.error
                : ""
            }
            onClick={() => {
              topUpBalance({
                amount: Number(balanceTopUpValue),
              })
                .unwrap()
                .then(({ pageUrl }) => {
                  window.open(pageUrl, "_blank");
                })
                .catch(() => {});
            }}
          />
        </div>
      </>
    </CRMModalLayout>
  );
}
