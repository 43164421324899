import { useParams } from "react-router-dom";
import { useReferenceBookValueAllQuery } from "@RTKQuery/reference-book-values/api";
import { ReferenceBookValue } from "@interfaces/refence-book";
import { Spinner } from "@ui";
import style from "./TableValuesBody.module.scss";

export function TableValuesBody({
  children,
}: {
  children: (data: ReferenceBookValue) => JSX.Element;
}) {
  const { book } = useParams();
  const { data, isFetching, isError } = useReferenceBookValueAllQuery(
    Number(book || "-1"),
  );

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодного значення.</p>
      </div>
    );
  } else {
    return <>{data?.map((item) => children(item))}</>;
  }
}
