import { useParams } from "react-router-dom";
import style from "./TopPanel.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";

export function TopPanel() {
  const dispatch = useAppDispatch();
  const { book } = useParams();

  return (
    <div className={style["topPanel"]}>
      <div></div>
      <div className={style["button"]}>
        <Button
          type="add"
          size="big"
          text={!book ? "Додати довідник" : "Додати значення"}
          onClick={() => {
            if (!book) {
              dispatch(showModal({ name: "AddBookModal" }));
            } else {
              dispatch(showModal({ name: "AddBookValueModal" }));
            }
          }}
        />
      </div>
    </div>
  );
}
