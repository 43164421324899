import {
  product_selected_variation_addData,
  product_selected_variation_deleteData,
} from "@global-state/pages/products/slice";
import { ProductExtended } from "@interfaces/product";
import { Checkbox } from "@ui";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function VariationsCheckbox({
  variation,
}: {
  variation: {
    id?: number;
    sku?: string;
    values: ProductExtended["variations"];
  };
}) {
  const { productId } = useParams();

  const variationProcesDeleted = useAppSelector((state) => {
    return state.productsPage.product.selected_variation;
  }).childIds.filter((item) => item.tag === "multiple");

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={variationProcesDeleted.some((item) => item.id === variation.id)}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            product_selected_variation_addData({
              parentId: Number(productId || "-1"),
              childIds: [
                {
                  id: variation.id || -1,
                  tag: "multiple",
                },
              ],
            }),
          );
        } else {
          dispatch(
            product_selected_variation_deleteData({
              id: variation.id || -1,
              singleOnly: false,
            }),
          );
        }
      }}
    />
  );
}
