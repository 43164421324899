import {
  TableBooksBody,
  TableBooksHead,
  TableBooksItem,
  TableBooksWrapper,
} from "./components";

export function TableBooks() {
  return (
    <TableBooksWrapper>
      <>
        <TableBooksHead />
        <TableBooksBody>
          {(book, group) => (
            <TableBooksItem key={book.id} book={book} group={group} />
          )}
        </TableBooksBody>
      </>
    </TableBooksWrapper>
  );
}
