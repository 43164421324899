import {
  TableTemplateBody,
  TableTemplateHead,
  TableTemplateItem,
  TableTemplateWrapper,
} from "./components";

export function TableTemplate() {
  return (
    <TableTemplateWrapper>
      <>
        <TableTemplateHead />
        <TableTemplateBody>
          {(template) => (
            <TableTemplateItem key={template.id} template={template} />
          )}
        </TableTemplateBody>
      </>
    </TableTemplateWrapper>
  );
}
