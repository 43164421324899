import { Link, useParams } from "react-router-dom";
import { IoStar } from "react-icons/io5";
import cn from "classnames";

import style from "./ProductVariationsItem.module.scss";
import { ProductExtended } from "@interfaces/product";
import { VariationsActions, VariationsCheckbox } from "./components";

export function ProductVariationsItem({
  data,
  parent,
}: {
  data: {
    id?: number;
    sku?: string;
    values: ProductExtended["variations"];
  };
  parent?: boolean;
}) {
  const { categoryId, productId, variationId } = useParams();

  const totalStrVariation = data.values.map(
    (item) => item.name + ": " + item.value,
  );

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  return (
    <div
      className={cn(
        style["row"],
        Number(variationId || productId) === data.id && style["row_active"],
      )}
    >
      <div className={cn(style["cell"], "!pl-0", !parent && "relative")}>
        {parent ? (
          <>
            <div className={style["mainStar"]}>
              <IoStar className={style["icon"]} />
              <div className={style["descr"]}>Батьківська варіація</div>
            </div>
          </>
        ) : (
          <>
            {!data.id ? (
              <div
                className={style["blocker"]}
                title="Спочатку збережіть зміни"
              />
            ) : null}
            <VariationsCheckbox variation={data} />
          </>
        )}
      </div>
      <ItemElementType
        dataId={data.id}
        linkTo={
          parent
            ? crm + `/catalog/${categoryId}/product/${productId}`
            : crm +
              `/catalog/${categoryId}/product/${productId}/variation/${data.id}`
        }
        className={cn(style["cell"])}
      >
        <p className={style["sku"]}>{data.sku}</p>
      </ItemElementType>
      <ItemElementType
        dataId={data.id}
        linkTo={
          parent
            ? crm + `/catalog/${categoryId}/product/${productId}`
            : crm +
              `/catalog/${categoryId}/product/${productId}/variation/${data.id}`
        }
        className={style["cell"]}
      >
        <p className={style["listCombinations"]}>
          {totalStrVariation.map((item, i) => (
            <span key={i} className="max-w-full truncate">
              {item + ";"}
            </span>
          ))}
        </p>
      </ItemElementType>
      <div className={cn(style["cell"], "relative justify-between !px-0")}>
        {!data.id ? (
          <div className={style["blocker"]} title="Спочатку збережіть зміни" />
        ) : null}
        <VariationsActions parent={parent} variationId={data.id} />
      </div>
    </div>
  );
}

function ItemElementType({
  className,
  linkTo,
  children,
  dataId,
}: {
  className: string;
  linkTo: string;
  children: JSX.Element;
  dataId: number | undefined;
}) {
  if (dataId) {
    return (
      <Link to={linkTo} className={className}>
        {children}
      </Link>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
}
