import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  TopPanel,
  ProductImages,
  ProductVariations,
  BasicInformation,
} from "../../../../components/catalog/product";
import cn from "classnames";
import style from "./ProductOnePage.module.scss";

import { useProductsOneQuery } from "@RTKQuery/products/api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  AddPicturesModal,
  AddVariationsModal,
  BlockerModal,
  DeleteVariationModal,
} from "@modals";
import {
  clearAllProductData,
  setAuthenticProductObject,
} from "@global-state/pages/product/slice";
import { Spinner } from "@ui";

export function ProductOnePage() {
  const { variationId } = useParams();

  const authenticProductObject = useAppSelector(
    (state) => state.productPage.authenticProductObject,
  );

  const basicFormRef = useRef<HTMLButtonElement>(null);
  const scrollElementRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const sourceProductInformation = variationId
    ? authenticProductObject?.children.find(
        (item) => item.id === Number(variationId),
      )
    : authenticProductObject?.product;

  useEffect(() => {
    return () => {
      dispatch(clearAllProductData());
    };
  }, []);

  useEffect(() => {
    if (scrollElementRef.current) {
      smoothScrollToTop(scrollElementRef.current, 60);
    }
  }, [variationId]);

  return (
    <main className={style["layout"]}>
      <TopPanel basicFormRef={basicFormRef} />
      <div
        className={cn(style["scrollContainer"], "scroll")}
        ref={scrollElementRef}
      >
        <div className={style["flexContainer"]}>
          <BasicInformation
            sourceProductInformation={sourceProductInformation}
            basicFormRef={basicFormRef}
          />
          <ProductImages images={sourceProductInformation?.pictures} />
        </div>
        <ProductVariations />
      </div>
      <>
        <BlockerModal oldDataActiveProduct={sourceProductInformation} />
        <DeleteVariationModal />
        <AddVariationsModal />
        <AddPicturesModal />
      </>
    </main>
  );
}

export function CreateProduct({ children }: { children: JSX.Element }) {
  return children;
}

export function EditProduct({ children }: { children: JSX.Element }) {
  const { productId } = useParams();
  const {
    data: product,
    isFetching,
    isError,
  } = useProductsOneQuery(+(productId || "-1"));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAuthenticProductObject(product));
  }, [product]);

  if (isFetching) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="fill-[#FFFFFF] dark:fill-[#2C2D31]" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#4674B8] max-sm:text-[18px]">
          Щось пішло не так.
        </p>
      </div>
    );
  } else {
    return children;
  }
}

function smoothScrollToTop(element: HTMLDivElement, duration: number) {
  let heightTop = element.scrollTop;

  requestAnimationFrame(step);
  let px = 0;
  function step(): void {
    px += duration;
    let r;

    r = heightTop - Math.min(px, heightTop);
    element.scrollTo(0, r);

    if (r) {
      requestAnimationFrame(step);
    }
  }
}
