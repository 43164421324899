import User from "@interfaces/user";
import CRMUser from "../inteface";
import Role, { Rermission } from "@interfaces/role";

export interface SetCRMUserAllInterface extends CRMUser {
  user: User;
  role: Role;
  profile_status: {
    is_active: boolean;
    is_verified: boolean;
    is_visible: boolean;
  };
}

export function setCRMUserAllDTO(data: any): SetCRMUserAllInterface {
  return {
    user: {
      uuid: data.profile.uuid,
      first_name: data.profile.first_name,
      lang: data.profile.lang,
      last_name: data.profile.last_name,
      tz: data.profile.tz,
      email: data.profile.email,
      phone: data.profile.phone,
    },
    role: {
      uuid: data.profile.role.uuid,
      is_custom: data.profile.role.is_custom,
      is_system: data.profile.role.is_system,
      permissions: (data.profile.role.permissions as Rermission[]).map(
        (permission: Rermission) => ({
          uuid: permission.uuid,
          name: permission.name,
          description: permission.description,
          group: permission.group,
          guard_name: permission.guard_name,
        }),
      ),
      role_description: data.profile.role.role_description,
      role_name: data.profile.role.role_name,
      role_title: data.profile.role.role_title,
    },
    profile_status: {
      is_active: data.profile.is_active,
      is_verified: data.profile.is_verified,
      is_visible: data.profile.is_visible,
    },
  };
}
