import { useCategoryCreateMutation } from "@RTKQuery/categories/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AddCategoryForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";

const MODAL_NAME = "AddCategoryModal";

export function AddCategoryModal() {
  const [_, { isSuccess, reset }] = useCategoryCreateMutation({
    fixedCacheKey: "add-category",
  });

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal) {
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Додати нову категорію"
    >
      <AddCategoryForm />
    </CRMModalLayout>
  );
}
