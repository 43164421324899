import { Outlet } from "react-router-dom";
import {
  CurrentPage,
  MenuBooksGroups,
  TopPanel,
} from "../../../components/catalog/reference-book";
import {
  AddBookGroupModal,
  AddBookModal,
  AddBookValueModal,
  DeleteBookGroupModal,
  DeleteBookModal,
  DeleteBookValueModal,
  EditBookGroupModal,
  EditBookModal,
  EditBookValueModal,
} from "@modals";

import style from "./ReferenceBookPage.module.scss";

export function ReferenceBookPage() {
  return (
    <main className={style["layout"]}>
      <div className={style["container"]}>
        <CurrentPage />
        <TopPanel />
        <MenuBooksGroups />
        <div className={style["page"]}>
          <Outlet />
        </div>
        <>
          <AddBookModal />
          <AddBookGroupModal />
          <AddBookValueModal />
          <DeleteBookModal />
          <DeleteBookGroupModal />
          <DeleteBookValueModal />
          <EditBookModal />
          <EditBookGroupModal />
          <EditBookValueModal />
        </>
      </div>
    </main>
  );
}
