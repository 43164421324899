import { BalanceHistoryRecord } from "@interfaces/balance";
import { useBalanceHistoryQuery } from "@RTKQuery/balance/api";
import style from "./HistoryBalanceBody.module.scss";
import { Spinner } from "@ui";

export function HistoryBalanceBody({
  children,
}: {
  children: (data: BalanceHistoryRecord) => JSX.Element;
}) {
  const { data, isFetching, isError } = useBalanceHistoryQuery();

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!data || data?.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Історія пуста.</p>
      </div>
    );
  } else {
    return <>{data?.map((item) => children(item))}</>;
  }
}
