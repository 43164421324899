import { ConnectedProduct } from "@interfaces/stock";
import style from "./ProvidersList.module.scss";

export function ProvidersList({
  data,
  children,
}: {
  data: ConnectedProduct[] | undefined;
  children: (data: ConnectedProduct) => JSX.Element;
}) {
  return (
    <div className={style["list"]}>{data?.map((item) => children(item))}</div>
  );
}
