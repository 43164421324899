import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import {
  useReferenceBookAllQuery,
  useReferenceBookUpdateMutation,
} from "@RTKQuery/reference-book/api";
import { useAppSelector } from "store/hooks";
import { Input, Select, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  friends: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .max(36, "Максимальна довжина рядка 36 символів")
        .required("Назва довідника - обов'язкове поле"),
      group_id: Yup.string()
        .max(36, "Максимальна довжина рядка 36 символів")
        .required("Група - обов'язкове поле"),
    }),
  ),
});

export function EditBookForm() {
  const [
    updateDictionary,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useReferenceBookUpdateMutation({
    fixedCacheKey: "edit-book",
  });

  const listGroup = useReferenceBookAllQuery().data?.map((item) => ({
    id: item.id,
    label: item.group,
    value: item.id + "",
  }));

  const activeMode = useAppSelector((state) => state.modalState.mode);

  const edit_books = useAppSelector(
    (state) => state.referenceBookPage.edit_books,
  );

  const modal = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  useEffect(() => {
    modal.current?.getBoundingClientRect().height === 300
      ? modal.current?.classList.add("overflow-auto")
      : modal.current?.classList.remove("overflow-auto");
  }, [activeMode]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        friends: edit_books
          .filter((item) => item.tag === activeMode)
          .map((item) => ({
            id: item.id,
            name: item.name,
            group_id: item.group_id + "",
          })),
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        updateDictionary(
          values.friends.map((item) => ({
            id: item.id,
            name: item.name,
            group_id: +item.group_id,
          })),
        );
      }}
    >
      <Form>
        <div ref={modal} className="scroll mb-[40px] max-h-[300px] px-[10px]">
          {edit_books.filter((item) => item.tag === activeMode).length > 0 &&
            edit_books
              .filter((item) => item.tag === activeMode)
              .map((friend, index) => (
                <div
                  key={friend.id}
                  className="mb-[20px] flex gap-[15px] max-sm:mb-[30px] max-sm:flex-col max-sm:gap-[10px]"
                >
                  <div className="w-[50%] max-sm:w-[100%]">
                    <Input
                      formikName={`friends.${index}.name`}
                      label="Нова назва довідника"
                      inputType="text"
                    />
                  </div>
                  <div className="w-[50%] max-sm:w-[100%]">
                    <Select
                      formikName={`friends.${index}.group_id`}
                      label="Нова група"
                      listValue={listGroup || []}
                    />
                  </div>
                </div>
              ))}
        </div>
        <SubmitButton
          type="submit"
          disabled={isLoading}
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={
            error
              ? (error as unknown as { data: { error: string } }).data.error
              : ""
          }
        />
      </Form>
    </Formik>
  );
}
