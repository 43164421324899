import User from "@interfaces/user";
import SuperadminSlice from "../interface";
import Tenant, { TenantDelete } from "@interfaces/tenant";

export interface SetSuperadminAllInterface extends SuperadminSlice {
  user: Omit<User, "phone">;
  tenants: Tenant[];
  deleted_tenants: TenantDelete[];
}

export function setSuperadminAllDTO(data: any): SetSuperadminAllInterface {
  return {
    user: data.user_data,
    tenants: data.tenants,
    deleted_tenants: data.deleted_tenants,
  };
}
