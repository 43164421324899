import { api } from "..";
import ProductsTemplate, {
  Characteristic,
} from "../../interfaces/products-template";
import { PartialExcept } from "store/store";

const characteristicsApi = api.injectEndpoints({
  endpoints: (build) => ({
    characteristicsCreate: build.mutation<
      Characteristic,
      Omit<Characteristic, "id">
    >({
      query: (characteristics) => ({
        url: "chars",
        method: "POST",
        body: characteristics,
      }),
      async onQueryStarted({ pattern_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData(
              "productsTemplateOne",
              pattern_id || -1,
              (draft) => {
                draft.characteristics = [...draft.characteristics, updatedPost];
              },
            ),
          );
        } catch {}
      },
    }),
    characteristicsUpdate: build.mutation<
      ProductsTemplate,
      PartialExcept<Characteristic, "id" | "pattern_id">
    >({
      query: ({ id, ...characteristics }) => ({
        url: `chars/${id}`,
        method: "PUT",
        body: characteristics,
      }),
      async onQueryStarted({ pattern_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsTemplateOne",
              pattern_id || -1,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    characteristicsDelete: build.mutation<
      ProductsTemplate,
      {
        characteristicsIds: Characteristic["id"][];
        pattern_id: Characteristic["pattern_id"];
      }
    >({
      query: ({ characteristicsIds, pattern_id }) => {
        const listItem = characteristicsIds
          .map((id) => `items=${id}`)
          .join("&");

        return {
          url: `chars?${listItem}&pattern_id=${pattern_id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ pattern_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsTemplateOne",
              pattern_id || -1,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCharacteristicsCreateMutation,
  useCharacteristicsUpdateMutation,
  useCharacteristicsDeleteMutation,
} = characteristicsApi;
