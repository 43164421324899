import { AllVariationCheckbox } from "./components";
import style from "./ListVariationsHead.module.scss";

export function ListVariationsHead() {
  return (
    <div className={style["listHeader"]}>
      <AllVariationCheckbox />
      <div className={style["label"]}>Варіації</div>
    </div>
  );
}
