import cn from "classnames";
import style from "./CRMModalLayout.module.scss";
import React from "react";

export function CRMModalLayout({
  isShow,
  hiddenModal,
  title,
  description,
  children,
  className,
}: {
  isShow: boolean;
  hiddenModal: () => void;
  title?: string;
  description?: string | JSX.Element;
  children: JSX.Element;
  className?: string;
}) {
  return (
    <div
      className={cn(style["backdrop"], isShow ? "flex" : "hidden")}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          hiddenModal();
        }
      }}
    >
      <div className={cn(style["modal"], className)}>
        {title ? (
          <p className={cn(style["title"], description && "!mb-[20px]")}>
            {title}
          </p>
        ) : null}
        {description ? (
          <p className={style["description"]}>{description}</p>
        ) : null}
        {children}
      </div>
    </div>
  );
}
