import { AddVariationForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { useProductUpdateVariationsMutation } from "@RTKQuery/products/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "AddVariationsModal";

export function AddVariationsModal() {
  const [_, { isSuccess, reset }] = useProductUpdateVariationsMutation({
    fixedCacheKey: "update-product-variations",
  });

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal) {
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Додати нові варіації"
    >
      <AddVariationForm />
    </CRMModalLayout>
  );
}
