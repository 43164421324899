import { useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import style from "./DeleteCategoryModal.module.scss";
import { useCategoryDeleteMutation } from "@RTKQuery/categories/api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { Button, SubmitButton } from "@ui";
import { hideModal } from "@global-state/entity/modal-state/slice";

const MODAL_NAME = "DeleteCategoryModal";

export function DeleteCategoryModal() {
  const [
    deleteCategory,
    { isLoading, isError, isUninitialized, isSuccess, reset, error },
  ] = useCategoryDeleteMutation({
    fixedCacheKey: "delete-category",
  });

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const category_delete = useAppSelector(
    (state) => state.productsPage.category.delete,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [queryParams] = useSearchParams();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal) {
        reset();
      } else {
        if (categoryId === String(category_delete?.id || "")) {
          navigate(
            crm +
              "/catalog/" +
              (category_delete?.parent_id || "0") +
              "?limit=" +
              (queryParams.get("limit") || "20") +
              "&offset=" +
              (queryParams.get("offset") || "0"),
          );
        }
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Ви впевнені, що хочете видалити категорію?"
    >
      <>
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="submit"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Підтвердити"
              successText="Успішно видалено"
              errorText="Не вдалось видалити"
              errorMesage={""}
              onClick={() => {
                deleteCategory(category_delete?.id || -1);
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
