import { Characteristic } from "@interfaces/products-template";
import { CharacteristicButtons, CharacteristicCheckbox } from "./components";
import style from "./ListCharacteristicsItem.module.scss";

export function ListCharacteristicsItem({
  characteristic,
}: {
  characteristic: Characteristic;
}) {
  return (
    <div className={style["variation"]}>
      <CharacteristicCheckbox characteristic={characteristic} />
      <p className={style["value"]}>{characteristic.name}</p>
      <CharacteristicButtons characteristic={characteristic} />
    </div>
  );
}
