import {
  AddCategoryModal,
  DeleteCategoryModal,
  DeleteProductModal,
  EditCategoryModal,
} from "@modals";
import {
  MultipleActions,
  Pagination,
  TableProduct,
  TopPanel,
} from "../../../../components/catalog/products";

import style from "./ProductAllPage.module.scss";
import { MenuCategories } from "components/common/MenuCategories";

export function ProductAllPage() {
  return (
    <main className={style["layout"]}>
      <div className={style["container"]}>
        <p className={style["currentPage"]}>Товари</p>
        <MenuCategories pageName="catalog" />
        <TopPanel />
        <div className={style["tableBox"]}>
          <TableProduct />
          <MultipleActions />
          <Pagination />
        </div>
        <>
          <AddCategoryModal />
          <DeleteCategoryModal />
          <EditCategoryModal />
          <DeleteProductModal />
        </>
      </div>
    </main>
  );
}
