import { useUnloadingAllQuery } from "@RTKQuery/unloading/api";
import { useParams } from "react-router-dom";
import { UnloadingsItem } from "../UnloadingsItem";
import style from "./UnloadingsList.module.scss";
import { Spinner } from "@ui";

export function UnloadingsList() {
  const { marketplaceId } = useParams();

  const { data, isFetching, isError } = useUnloadingAllQuery(
    Number(marketplaceId),
  );

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодної вигрузки.</p>
      </div>
    );
  } else {
    return (
      <div className={style["list"]}>
        {data.map((item) => (
          <UnloadingsItem key={item.id} data={item} />
        ))}
      </div>
    );
  }
}
