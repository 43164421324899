import { useParams } from "react-router-dom";
import style from "./UnloadingOnePage.module.scss";
import { useUnloadingOneQuery } from "@RTKQuery/unloading/api";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import {
  clearAllUnloadingData,
  setAuthenticUnloadingObject,
} from "@global-state/pages/unloading/slice";
import {
  LongSettings,
  ProductsCategories,
  ProductsManager,
  QuickSettings,
  TopPanel,
} from "components/catalog/unloading";
import cn from "classnames";
import { SetHoroshopCategoriesModal } from "@modals";
import { Spinner } from "@ui";

export function UnloadingOnePage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllUnloadingData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <TopPanel />
      <div className={cn(style["scrollContainer"], "scroll")}>
        <div className={style["flexContainer"]}>
          <QuickSettings />
          <LongSettings />
        </div>
        <div className={style["flexContainer"]}>
          <ProductsCategories />
          <ProductsManager />
        </div>
      </div>
      <SetHoroshopCategoriesModal />
    </main>
  );
}

export function CreateUnloading({ children }: { children: JSX.Element }) {
  return children;
}

export function EditUnloading({ children }: { children: JSX.Element }) {
  const { unloadingId } = useParams();
  const { data, isFetching, isError } = useUnloadingOneQuery(
    +(unloadingId || "-1"),
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setAuthenticUnloadingObject(data));
    }
  }, [data]);

  if (isFetching) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="fill-[#FFFFFF] dark:fill-[#2C2D31]" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#4674B8] max-sm:text-[18px]">
          Щось пішло не так.
        </p>
      </div>
    );
  } else {
    return children;
  }
}
