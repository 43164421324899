import cn from "classnames";
import style from "./MenuBooksGroups.module.scss";
import { useState } from "react";
import {
  MenuBooksGroupsAddButton,
  MenuBooksGroupsItem,
  MenuBooksGroupsLabel,
  MenuBooksGroupsList,
} from "./components";

export function MenuBooksGroups() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={style["sidebar"]}>
      <MenuBooksGroupsLabel isOpen={isOpen} setIsOpen={setIsOpen} />
      <div
        className={cn(style["groupsBox"], !isOpen && style["groupsBox_close"])}
      >
        <MenuBooksGroupsList>
          {(data) => <MenuBooksGroupsItem key={data.id} data={data} />}
        </MenuBooksGroupsList>
        <MenuBooksGroupsAddButton />
      </div>
    </div>
  );
}
