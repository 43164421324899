import { useAppDispatch } from "store/hooks";
import style from "./Tenants.module.scss";
import { List } from "./components";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";

export function Tenants() {
  const dispatch = useAppDispatch();

  return (
    <div className={style["container"]}>
      <p className={style["title"]}>Доступні CRM</p>
      <List />
      <div className={style["buttonsContainer"]}>
        <div className={style["buttonBox"]}>
          <Button
            type="add"
            onClick={() => {
              dispatch(
                showModal({
                  name: "CreateTenantModal",
                }),
              );
            }}
            size="big"
            text="Додати нову CRM"
            variant="contained"
          />
        </div>
        <div className={style["buttonBox"]}>
          <Button
            type="add"
            onClick={() => {}}
            disabled
            size="big"
            text="Додати існуючу CRM"
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
}
