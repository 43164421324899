import { useEffect, useState } from "react";
import { useField } from "formik";
import cn from "classnames";
import style from "./Branch.module.scss";
import Category from "@interfaces/category";
import { SpoilerButton } from "components/common/UI/buttons/SpoilerButton";

export function Branch({
  data,
  changeValue,
}: {
  data: Category;
  changeValue(data: { value: string; label: string }): void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [field] = useField("category_id");

  useEffect(() => {
    if (data.id === +field.value) {
      changeValue({
        label: data.name["uk"],
        value: String(data.id),
      });
    }
  }, []);

  return (
    <div className={cn(style["treeBranch"])}>
      <div
        className={cn(
          style["value"],
          data.id === +field.value && style["value_active"],
        )}
      >
        <p
          title={data.name["uk"]}
          onClick={() =>
            changeValue({
              label: data.name["uk"],
              value: String(data.id),
            })
          }
          className={style["text"]}
        >
          {data.name["uk"]}
        </p>
        <div className="flex items-center" data-select>
          {data.children.length > 0 && (
            <SpoilerButton
              className={style["spoilerButtonCustom"]}
              isOpen={isOpen}
              onClick={() => setIsOpen((state) => !state)}
            />
          )}
        </div>
      </div>
      <div className={style["divider"]}></div>
      {data.children.length > 0 && (
        <div
          className={cn(
            style["listValues"],
            isOpen ? "ml-[10px] h-fit" : "h-0",
          )}
        >
          {data.children.map((item) => (
            <Branch key={item.id} data={item} changeValue={changeValue} />
          ))}
        </div>
      )}
    </div>
  );
}
