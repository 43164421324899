import {
  productsTemplate_delete_clearData,
  productsTemplate_delete_setData,
} from "@global-state/pages/productsTemplate/slice";
import { useProductsTemplateAllQuery } from "@RTKQuery/products-template/api";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllTemplateCheckbox() {
  const { data } = useProductsTemplateAllQuery();

  const delete_template = useAppSelector(
    (state) => state.productsTemplatePage.products_template.delete,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        data?.length ===
        delete_template.filter((item) => item.tag === "multiple").length
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            productsTemplate_delete_setData(
              data?.map((item) => ({
                id: item.id,
                tag: "multiple",
              })) || [],
            ),
          );
        } else {
          dispatch(productsTemplate_delete_clearData());
        }
      }}
    />
  );
}
