import { useState } from "react";
import {
  TableProductsProvidersBody,
  TableProductsProvidersHead,
  TableProductsProvidersItem,
  TableProductsProvidersWrapper,
} from "./components";
import { ProductProvider } from "@interfaces/stock";

export function TableProductsProviders({ data }: { data?: ProductProvider[] }) {
  const [sortBy, setSortBy] = useState<
    "provider/asc" | "provider/desc" | "name/asc" | "name/desc" | ""
  >("");

  return (
    <TableProductsProvidersWrapper>
      <>
        <TableProductsProvidersHead sortBy={sortBy} setSortBy={setSortBy} />
        <TableProductsProvidersBody
          sortBy={sortBy.split("/")[0]}
          order={sortBy.split("/")[1]}
        >
          {(data) => <TableProductsProvidersItem key={data.id} data={data} />}
        </TableProductsProvidersBody>
      </>
    </TableProductsProvidersWrapper>
  );
}
