import { Button } from "@ui";
import style from "./TopPanel.module.scss";
import { useAppDispatch } from "store/hooks";
import { showModal } from "@global-state/entity/modal-state/slice";

export function TopPanel() {
  const dispatch = useAppDispatch();

  return (
    <div className={style["topPanel"]}>
      <div className={style["container"]}>
        <p className={style["currentPage"]}>Наявність товарів</p>
      </div>
      <Button
        type="add"
        size="big"
        customStyle={{
          button: "w-[270px] h-[43px] max-sm:w-full",
        }}
        text="Додати постачальника"
        onClick={() => {
          dispatch(
            showModal({
              name: "AddProductProviderModal",
            }),
          );
        }}
      />
    </div>
  );
}
