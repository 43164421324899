import { Link } from "react-router-dom";
import { TemplateButtons, TemplateCheckbox } from "./components";
import style from "./TableTemplateItem.module.scss";
import ProductsTemplate from "@interfaces/products-template";

export function TableTemplateItem({
  template,
}: {
  template: Pick<ProductsTemplate, "id" | "name">;
}) {
  return (
    <div className={style["item"]}>
      <TemplateCheckbox template={template} />
      <Link
        to={template.id + ""}
        title={template.name}
        className={style["link"]}
      >
        <p className="truncate">{template.name}</p>
      </Link>
      <TemplateButtons template={template} />
    </div>
  );
}
