import { ConnectedProduct } from "@interfaces/stock";
import { Button } from "@ui";
import style from "./ProvidersItemHeader.module.scss";
import { useAppDispatch } from "store/hooks";
import { showModal } from "@global-state/entity/modal-state/slice";
import { setDeleteProductProviderId } from "@global-state/pages/stock/slice";

export function ProvidersItemHeader({
  data,
}: {
  data: Pick<ConnectedProduct, "block_id" | "supplier">;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["header"]}>
      <div className={style["name"]}>{data.supplier.name}</div>
      <Button
        customStyle={{
          button: style["customButton"],
        }}
        size="small"
        type="delete"
        onClick={() => {
          dispatch(setDeleteProductProviderId(data.block_id));
          dispatch(
            showModal({
              name: "DeleteProductProviderModal",
            }),
          );
        }}
      />
    </div>
  );
}
