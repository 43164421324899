import { Link } from "react-router-dom";
import { BookButtons, BookCheckbox } from "./components";
import style from "./TableBooksItem.module.scss";
import ReferenceBookGroup, { ReferenceBook } from "@interfaces/refence-book";

export function TableBooksItem({
  book,
  group,
}: {
  book: ReferenceBook;
  group: ReferenceBookGroup;
}) {
  const crm = window.localStorage.getItem("subdomain");

  return (
    <div className={style["item"]}>
      <BookCheckbox book={book} group={group} />
      <Link
        to={
          process.env.NODE_ENV === "development"
            ? `/${crm}/reference-book/${group.id}/values/${book.id}`
            : `/reference-book/${group.id}/values/${book.id}`
        }
        title={book.name}
        className={style["link"]}
      >
        <p className="truncate">{book.name}</p>
      </Link>
      <BookButtons book={book} group={group} />
    </div>
  );
}
