import { EditTemlateForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  productsTemplate_delete_deleteData,
  productsTemplate_edit_clearData,
} from "@global-state/pages/productsTemplate/slice";

import { useProductsTemplateUpdateMutation } from "@RTKQuery/products-template/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "EditTemplateModal";

export function EditTemplateModal() {
  const [_, { isSuccess, reset }] = useProductsTemplateUpdateMutation({
    fixedCacheKey: "edit-template",
  });

  const edit_template = useAppSelector(
    (state) => state.productsTemplatePage.products_template.edit,
  );
  const activeMode = useAppSelector((state) => state.modalState.modalName);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeMode) {
        dispatch(productsTemplate_edit_clearData());
        dispatch(
          productsTemplate_delete_deleteData({
            id: edit_template?.id || -1,
            singleOnly: false,
          }),
        );

        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeMode]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess) {
      dispatch(productsTemplate_edit_clearData());
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeMode === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Відредагувати шаблон"
    >
      <EditTemlateForm />
    </CRMModalLayout>
  );
}
