import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActionsBook.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";
import cn from "classnames";

export function MultipleActionsBook() {
  const delete_books = useAppSelector(
    (state) => state.referenceBookPage.delete_books,
  );
  const edit_books = useAppSelector(
    (state) => state.referenceBookPage.edit_books,
  );

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn(
        style["multipleActions"],
        (delete_books.some((item) => item.tag === "multiple") ||
          edit_books.some((item) => item.tag === "multiple")) &&
          "h-[50px]",
      )}
    >
      <div className={style["button"]}>
        <Button
          type="delete"
          size="big"
          text="Видалити"
          onClick={() => {
            dispatch(
              showModal({
                name: "DeleteBookModal",
                mode: "multiple",
              }),
            );
          }}
        />
      </div>
      <div className={style["button"]}>
        <Button
          type="edit"
          size="big"
          text="Редагувати"
          variant="outlined"
          onClick={() => {
            dispatch(
              showModal({
                name: "EditBookModal",
                mode: "multiple",
              }),
            );
          }}
        />
      </div>
    </div>
  );
}
