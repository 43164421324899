import { createSlice } from "@reduxjs/toolkit";
import { SetSuperadminAllInterface } from "./dto/setSuperadminAll.dto";
import SuperAdmin from "@interfaces/superadmin";
import { SliceAction } from "store/store";
import Tenant, { TenantDelete } from "@interfaces/tenant";

const initialState: SuperAdmin = {
  user: {
    first_name: "",
    lang: "",
    last_name: "",
    tz: "",
    uuid: "",
    email: "",
  },
  tenants: [],
  deleted_tenants: [],
};

const superadminSlice = createSlice({
  initialState,
  name: "superadmin",
  reducers: {
    setSuperadminAll(state, action: SliceAction<SetSuperadminAllInterface>) {
      return action.payload;
    },

    setTenant(state, action: SliceAction<Tenant[]>) {
      state.tenants = action.payload;
    },

    setDeleteTenant(state, action: SliceAction<TenantDelete[]>) {
      state.deleted_tenants = action.payload;
    },

    clearSuperadminAll: (state) => {
      state.user = {
        first_name: "",
        lang: "",
        last_name: "",
        tz: "",
        uuid: "",
        email: "",
      };
      state.tenants = [];
      state.deleted_tenants = [];
    },
  },
});

const { reducer, actions } = superadminSlice;

export default reducer;

export const {
  setSuperadminAll,
  setTenant,
  setDeleteTenant,

  clearSuperadminAll,
} = actions;
