import { api } from "..";
import ProductsTemplate, {
  Variation,
} from "../../interfaces/products-template";
import { PartialExcept } from "store/store";

const variationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    variationsCreate: build.mutation<Variation, Omit<Variation, "id">>({
      query: (variations) => ({
        url: "variations",
        method: "POST",
        body: variations,
      }),
      async onQueryStarted({ pattern_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData(
              "productsTemplateOne",
              pattern_id || -1,
              (draft) => {
                draft.variations = [...draft.variations, updatedPost];
              },
            ),
          );
        } catch {}
      },
    }),
    variationsUpdate: build.mutation<
      ProductsTemplate,
      PartialExcept<Variation, "id" | "pattern_id">
    >({
      query: ({ id, ...variations }) => ({
        url: `variations/${id}`,
        method: "PUT",
        body: variations,
      }),
      async onQueryStarted({ pattern_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsTemplateOne",
              pattern_id || -1,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
    variationsDelete: build.mutation<
      ProductsTemplate,
      { variationsIds: Variation["id"][]; pattern_id: Variation["pattern_id"] }
    >({
      query: ({ variationsIds, pattern_id }) => {
        const listItem = variationsIds.map((id) => `items=${id}`).join("&");

        return {
          url: `variations?${listItem}&pattern_id=${pattern_id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ pattern_id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          dispatch(
            api.util.upsertQueryData(
              "productsTemplateOne",
              pattern_id || -1,
              updatedPost,
            ),
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useVariationsCreateMutation,
  useVariationsUpdateMutation,
  useVariationsDeleteMutation,
} = variationsApi;
