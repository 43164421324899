import { useCharacteristicsDeleteMutation } from "@RTKQuery/characteristics/api";
import style from "./DeleteParameterModal.module.scss";
import { useVariationsDeleteMutation } from "@RTKQuery/variations/api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { Button, SubmitButton } from "@ui";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  parameters_delete_clearData,
  parameters_delete_deleteData,
} from "@global-state/pages/productsTemplate/slice";

const MODAL_NAME = "DeleteParameterModal";

export function DeleteParameterModal() {
  const [
    deleteCharacteristic,
    {
      error: errorCharacteristic,
      isLoading: isLoadingCharacteristic,
      isError: isErrorCharacteristic,
      isUninitialized: isUninitializedCharacteristic,
      isSuccess: isSuccessCharacteristic,
      reset: resetCharacteristic,
    },
  ] = useCharacteristicsDeleteMutation();

  const [
    deleteVariation,
    {
      error: errorVariation,
      isLoading: isLoadingVariation,
      isError: isErrorVariation,
      isUninitialized: isUninitializedVariation,
      isSuccess: isSuccessVariations,
      reset: resetVariation,
    },
  ] = useVariationsDeleteMutation();

  const { modalName: activeModal, mode: activeMode } = useAppSelector(
    (state) => state.modalState,
  );

  const listItemsCharacteristic = useAppSelector(
    (state) => state.productsTemplatePage.parameters.delete_characteristic,
  );
  const listItemsVariation = useAppSelector(
    (state) => state.productsTemplatePage.parameters.delete_variation,
  );
  let listIdsCharacteristic: number[] = [];
  let listIdsVariation: number[] = [];

  if (activeMode === "single") {
    listIdsCharacteristic = [
      listItemsCharacteristic.find((item) => item.tag === "single")?.id || -1,
    ];
    listIdsVariation = [
      listItemsVariation.find((item) => item.tag === "single")?.id || -1,
    ];
  } else {
    listIdsCharacteristic = Array.from(
      new Set(listItemsCharacteristic.map((item) => item.id)),
    );
    listIdsVariation = Array.from(
      new Set(listItemsVariation.map((item) => item.id)),
    );
  }

  const dispatch = useAppDispatch();
  const location = useLocation();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccessVariations || isSuccessCharacteristic) {
      if (!activeModal) {
        if (activeMode === "single") {
          if (listItemsCharacteristic.length) {
            dispatch(
              parameters_delete_deleteData({
                data: {
                  id: listIdsCharacteristic[0],
                  singleOnly: false,
                },
                parameterType: "characteristic",
              }),
            );
          }
          if (listItemsVariation.length) {
            dispatch(
              parameters_delete_deleteData({
                data: {
                  id: listIdsVariation[0],
                  singleOnly: false,
                },
                parameterType: "variation",
              }),
            );
          }
        } else {
          if (listItemsCharacteristic.length) {
            dispatch(parameters_delete_clearData("characteristic"));
          }
          if (listItemsVariation.length) {
            dispatch(parameters_delete_clearData("variation"));
          }
        }
        resetCharacteristic();
        resetVariation();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccessVariations, isSuccessCharacteristic, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      resetVariation();
      resetCharacteristic();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isErrorCharacteristic, isErrorVariation]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (
      !isSuccessVariations &&
      !isSuccessCharacteristic &&
      activeMode === "single"
    ) {
      if (listItemsCharacteristic.length) {
        dispatch(
          parameters_delete_deleteData({
            data: {
              id: listIdsCharacteristic[0],
              singleOnly: true,
            },
            parameterType: "characteristic",
          }),
        );
      }
      if (listItemsVariation.length) {
        dispatch(
          parameters_delete_deleteData({
            data: {
              id: listIdsVariation[0],
              singleOnly: true,
            },
            parameterType: "variation",
          }),
        );
      }
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Ви впевнені, що хочете видалити вибрані параметри?"
    >
      <>
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="submit"
              disabled={isLoadingVariation || isLoadingCharacteristic}
              isUninitialized={
                isUninitializedVariation && isUninitializedCharacteristic
              }
              isLoading={isLoadingVariation || isLoadingCharacteristic}
              isSuccess={
                (isSuccessVariations || isSuccessCharacteristic) &&
                !isLoadingVariation &&
                !isLoadingCharacteristic
              }
              isError={
                (isErrorVariation || isErrorCharacteristic) &&
                !isLoadingVariation &&
                !isLoadingCharacteristic
              }
              uninitializedText="Підтвердити"
              successText="Успішно видалено"
              errorText="Не вдалось видалити"
              errorMesage={""}
              onClick={() => {
                if (listItemsCharacteristic.length) {
                  deleteCharacteristic({
                    characteristicsIds: listIdsCharacteristic,
                    pattern_id:
                      process.env.NODE_ENV === "development"
                        ? +location.pathname.split("/")[3]
                        : +location.pathname.split("/")[2],
                  });
                }
                if (listItemsVariation.length) {
                  deleteVariation({
                    variationsIds: listIdsVariation,
                    pattern_id:
                      process.env.NODE_ENV === "development"
                        ? +location.pathname.split("/")[3]
                        : +location.pathname.split("/")[2],
                  });
                }
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {errorVariation
            ? (errorVariation as unknown as { data: { error: string } }).data
                .error
            : ""}
        </p>
        <p className={style["errorMessage"]}>
          {errorCharacteristic
            ? (errorCharacteristic as unknown as { data: { error: string } })
                .data.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
