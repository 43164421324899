import { AllTemplateCheckbox } from "./components";
import style from "./TableTemplateHead.module.scss";

export function TableTemplateHead() {
  return (
    <div className={style["head"]}>
      <AllTemplateCheckbox />
      <div className={style["label"]}>Шаблони</div>
    </div>
  );
}
