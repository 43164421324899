import { useCategoriesAllQuery } from "@RTKQuery/categories/api";
import style from "./ProductsCategories.module.scss";
import cn from "classnames";
import { Branch } from "./components";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { hideModal } from "@global-state/entity/modal-state/slice";

export function ProductsCategories() {
  const { data } = useCategoriesAllQuery();

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const dispatch = useAppDispatch();

  useEffect(() => {
    function hideHandler(e: any) {
      if (activeModal === "ProductsCategories") {
        if (!e.target.closest("[data-nohide]")) {
          dispatch(hideModal());
        }
      }
    }
    document.body.removeEventListener("click", hideHandler);

    document.body.addEventListener("click", hideHandler);

    return () => {
      document.body.removeEventListener("click", hideHandler);
    };
  }, [activeModal]);

  return (
    <div
      data-nohide
      className={cn(
        style["container"],
        activeModal === "ProductsCategories" && "block",
      )}
    >
      <div className={cn(style["scrollContainer"], "scroll")}>
        <Branch
          data={{
            id: -1,
            name: { uk: "Всі товари" },
            parent_id: null,
            children: [],
          }}
        />
        <div className="ml-[20px]">
          {data?.map((item) => <Branch key={item.id} data={item} />)}
        </div>
      </div>
    </div>
  );
}
