import { useParams } from "react-router-dom";
import {
  ProvidersItemFooterItem,
  ProvidersItemFooterList,
  ProvidersItemFooterWrapper,
  ProvidersItemHeader,
  ProvidersItemMainBody,
  ProvidersItemMainHead,
  ProvidersItemMainItem,
  ProvidersItemMainWrapper,
  ProvidersItemWrapper,
  ProvidersList,
  ProvidersWrapper,
} from "./components";
import { useProductStockOneConnectedProductsQuery } from "@RTKQuery/stock/api";

export function Providers() {
  const { productId } = useParams();

  const { data } = useProductStockOneConnectedProductsQuery(Number(productId));

  return (
    <ProvidersWrapper countElement={data?.length || 0}>
      <ProvidersList data={data}>
        {({ block_id, stock, supplier }) => (
          <ProvidersItemWrapper key={block_id}>
            <>
              <ProvidersItemHeader data={{ block_id, supplier }} />
              <ProvidersItemMainWrapper>
                <>
                  <ProvidersItemMainHead productName={stock[0].name} />
                  <ProvidersItemMainBody
                    data={stock.filter((item) => item.connected_to)}
                  >
                    {(data) => (
                      <ProvidersItemMainItem
                        key={data.variation_id}
                        data={data}
                      />
                    )}
                  </ProvidersItemMainBody>
                </>
              </ProvidersItemMainWrapper>
              <ProvidersItemFooterWrapper>
                <ProvidersItemFooterList
                  data={stock.filter((item) => !item.connected_to)}
                >
                  {(data) => (
                    <ProvidersItemFooterItem
                      key={data.variation_id}
                      data={data}
                    />
                  )}
                </ProvidersItemFooterList>
              </ProvidersItemFooterWrapper>
            </>
          </ProvidersItemWrapper>
        )}
      </ProvidersList>
    </ProvidersWrapper>
  );
}
