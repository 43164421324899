import { Button, InputNoFormik } from "@ui";
import style from "./TopUpBalanceForm.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setBalanceTopUpValue } from "@global-state/pages/balance/slice";
import { showModal } from "@global-state/entity/modal-state/slice";

export function TopUpBalanceForm() {
  const balanceTopUpValue = useAppSelector(
    (state) => state.balancePage.balance,
  );

  const dispatch = useAppDispatch();

  return (
    <>
      <div className={style["inputs"]}>
        <InputNoFormik
          defaultValue={balanceTopUpValue}
          validate={(value) => value.replace(/[^0-9.]+|(\.\d*\.)/g, "")}
          setValue={(newValue) => {
            dispatch(setBalanceTopUpValue(newValue));
          }}
          label="Сума поповнення $"
          customStyle={{
            height: "h-[40px]",
          }}
        />
        <InputNoFormik
          defaultValue=""
          setValue={() => {}}
          label="Промокод"
          customStyle={{
            height: "h-[40px]",
          }}
          disabled
        />
      </div>
      <div className="h-[42px] w-[180px] max-sm:w-full">
        <Button
          size="big"
          text="Поповнити"
          disabled={!balanceTopUpValue}
          onClick={() => {
            dispatch(
              showModal({
                name: "PaymentMethodModal",
              }),
            );
          }}
        />
      </div>
    </>
  );
}
