export function DropSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M6.53665 2.92682C7.34488 2.92682 8.00007 2.27163 8.00007 1.46341C8.00007 0.655192 7.34488 0 6.53665 0C5.72843 0 5.07324 0.655192 5.07324 1.46341C5.07324 2.27163 5.72843 2.92682 6.53665 2.92682Z"
        fill="#CCCCCC"
      />
      <path
        d="M6.53665 7.96784C7.34488 7.96784 8.00007 7.31265 8.00007 6.50443C8.00007 5.69621 7.34488 5.04102 6.53665 5.04102C5.72843 5.04102 5.07324 5.69621 5.07324 6.50443C5.07324 7.31265 5.72843 7.96784 6.53665 7.96784Z"
        fill="#CCCCCC"
      />
      <path
        d="M6.53665 13.0089C7.34488 13.0089 8.00007 12.3537 8.00007 11.5454C8.00007 10.7372 7.34488 10.082 6.53665 10.082C5.72843 10.082 5.07324 10.7372 5.07324 11.5454C5.07324 12.3537 5.72843 13.0089 6.53665 13.0089Z"
        fill="#CCCCCC"
      />
      <path
        d="M1.46341 2.92682C2.27163 2.92682 2.92683 2.27163 2.92683 1.46341C2.92683 0.655192 2.27163 0 1.46341 0C0.655192 0 0 0.655192 0 1.46341C0 2.27163 0.655192 2.92682 1.46341 2.92682Z"
        fill="#CCCCCC"
      />
      <path
        d="M1.46341 7.96784C2.27163 7.96784 2.92683 7.31265 2.92683 6.50443C2.92683 5.69621 2.27163 5.04102 1.46341 5.04102C0.655192 5.04102 0 5.69621 0 6.50443C0 7.31265 0.655192 7.96784 1.46341 7.96784Z"
        fill="#CCCCCC"
      />
      <path
        d="M1.46341 13.0089C2.27163 13.0089 2.92683 12.3537 2.92683 11.5454C2.92683 10.7372 2.27163 10.082 1.46341 10.082C0.655192 10.082 0 10.7372 0 11.5454C0 12.3537 0.655192 13.0089 1.46341 13.0089Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}
