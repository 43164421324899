import cn from "classnames";
import style from "./MenuCategories.module.scss";
import { useState } from "react";
import {
  MenuCategoriesList,
  MenuCategoriesLabel,
  MenuCategoriesBranch,
  MenuCategoriesAddButton,
} from "./components";

export function MenuCategories({
  addRootCategoryBtn = true,
  addCategoryBtn = true,
  removeCategoryBtn = true,
  editCategoryBtn = true,
  showBranchCategoryBtn = true,
  pageName,
}: {
  addRootCategoryBtn?: boolean;
  addCategoryBtn?: boolean;
  removeCategoryBtn?: boolean;
  editCategoryBtn?: boolean;
  showBranchCategoryBtn?: boolean;
  pageName: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={style["sidebar"]}>
      <MenuCategoriesLabel isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={cn(style["menuBox"], !isOpen && style["menuBox_close"])}>
        <MenuCategoriesList>
          {(data) => (
            <MenuCategoriesBranch
              key={data.id}
              treeIds={String(data.id)}
              actions={data.id !== 0}
              data={data}
              addCategoryBtn={addCategoryBtn}
              removeCategoryBtn={removeCategoryBtn}
              editCategoryBtn={editCategoryBtn}
              showBranchCategoryBtn={showBranchCategoryBtn}
              pageName={pageName}
            />
          )}
        </MenuCategoriesList>
        {addRootCategoryBtn && <MenuCategoriesAddButton />}
      </div>
    </div>
  );
}
