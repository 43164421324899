import { useNavigate } from "react-router-dom";
import { ProductTable } from "@interfaces/product";
import { Button } from "@ui";
import style from "./ProductButtons.module.scss";

export function ProductButtons({ product }: { product: ProductTable }) {
  const navigate = useNavigate();

  return (
    <div className={style["buttons"]}>
      <Button
        type="edit"
        onClick={() => {
          navigate(`product/${product.id}`);
        }}
      />
    </div>
  );
}
