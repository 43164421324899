import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Branch } from "./components";
import cn from "classnames";
import style from "./CategorySelect.module.scss";
import { useCategoriesAllQuery } from "@RTKQuery/categories/api";
import { SpoilerButton } from "@ui";

export function CategorySelect() {
  const { data } = useCategoriesAllQuery();

  const [currentCategory, setCurrentCategory] = useState<{
    value: string;
    label: string;
  }>({
    value: "",
    label: "Невизначено",
  });
  const [field, meta] = useField("category_id");

  const [showListValue, setShowListValue] = useState<boolean>(false);

  const { setFieldValue } = useFormikContext<Record<string, any>>();

  function changeValue(data: { value: string; label: string }) {
    setFieldValue("category_id", data.value);
    setCurrentCategory(data);
    setShowListValue(false);
  }

  useEffect(() => {
    function eventAction(e: any) {
      if (!e.target.closest("[data-select]")) {
        setShowListValue(false);
      }
    }

    document.body.addEventListener("click", eventAction);

    return () => {
      document.body.removeEventListener("click", eventAction);
    };
  }, []);

  return (
    <div>
      <p className={style["label"]}>Категорія*</p>
      <div className="relative">
        <div
          data-select
          className={cn(
            style["container"],
            showListValue && "!border-input-main",
          )}
          onClick={() => {
            setShowListValue((state) => !state);
          }}
        >
          <input
            className={style["input"]}
            type="text"
            value={currentCategory?.label}
            readOnly
          />
          <SpoilerButton className="p-0" isOpen={showListValue} />
        </div>
        <div
          className={cn(
            style["listValues"],
            showListValue ? "flex" : "hidden",
            "scroll",
          )}
        >
          {data?.map((item) => (
            <Branch key={item.id} data={item} changeValue={changeValue} />
          ))}
        </div>
        {meta.touched && meta.error ? (
          <p className={style["errorMessage"]}>{meta.error}</p>
        ) : null}
      </div>
    </div>
  );
}
