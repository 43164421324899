import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useEffect } from "react";
import { useCharacteristicsUpdateMutation } from "@RTKQuery/characteristics/api";
import { useVariationsUpdateMutation } from "@RTKQuery/variations/api";
import { useAppSelector } from "store/hooks";
import { useReferenceBookAllQuery } from "@RTKQuery/reference-book/api";
import { useParams } from "react-router-dom";
import { Checkbox, Input, Select, SubmitButton } from "@ui";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва - обов'язкове поле"),
});

export function EditParameterForm() {
  const [
    updateCharacteristic,
    {
      error: errorCharacteristic,
      isLoading: isLoadingCharacteristic,
      isError: isErrorCharacteristic,
      isUninitialized: isUninitializedCharacteristic,
      isSuccess: isSuccessCharacteristic,
      reset: resetCharacteristic,
    },
  ] = useCharacteristicsUpdateMutation({
    fixedCacheKey: "edit-characteristic",
  });

  const [
    updateVariation,
    {
      error: errorVariation,
      isLoading: isLoadingVariation,
      isError: isErrorVariation,
      isUninitialized: isUninitializedVariation,
      isSuccess: isSuccessVariations,
      reset: resetVariations,
    },
  ] = useVariationsUpdateMutation({
    fixedCacheKey: "edit-variation",
  });

  const edit_parameters = useAppSelector(
    (state) => state.productsTemplatePage.parameters.edit,
  );

  const activeMode = useAppSelector((state) => state.modalState.mode);

  const { data } = useReferenceBookAllQuery();

  let displayReferenceBook =
    data?.reduce<{ id: number; value: string; label: string }[]>(
      (previousValue, group) => [
        ...previousValue,
        ...group.items.map((item) => ({
          id: item.id,
          value: item.id + "",
          label: group.group + ": " + item.name,
        })),
      ],
      [],
    ) || null;

  if (!displayReferenceBook || displayReferenceBook.length === 0) {
    displayReferenceBook = [
      {
        id: -1,
        value: "-1",
        label: "Не знайдено",
      },
    ];
  }

  const { id: idTemplate } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      resetVariations();
      resetCharacteristic();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isErrorCharacteristic, isErrorVariation]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: edit_parameters?.name || "",
        group: activeMode || "variation",
        reference_book: edit_parameters?.dictionary_id
          ? edit_parameters.dictionary_id + ""
          : displayReferenceBook[0].value,
        multi_selection: !!edit_parameters?.multi_selection,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        if (values.group === "variation") {
          updateVariation({
            id: edit_parameters!.id,
            pattern_id: Number(idTemplate),
            name: values.name,
            type: "dictionary",
            dictionary_id: +values.reference_book,
            multi_selection: values.multi_selection,
          });
        } else {
          updateCharacteristic({
            id: edit_parameters!.id,
            pattern_id: Number(idTemplate),
            name: values.name,
            type: "dictionary",
            dictionary_id: +values.reference_book,
            multi_selection: values.multi_selection,
          });
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mb-[40px] flex flex-col gap-[20px]">
            <Input formikName="name" label="Назва" inputType="text" />
            <Select
              formikName="reference_book"
              label="Довідник"
              listValue={
                displayReferenceBook || [
                  {
                    id: -1,
                    value: "-1",
                    label: "Не знайдено",
                  },
                ]
              }
            />
            <Checkbox
              label="Мультивибір"
              checked={values.multi_selection}
              onChange={() => {
                setFieldValue("multi_selection", !values.multi_selection);
              }}
            />
          </div>
          <SubmitButton
            type="submit"
            disabled={isLoadingVariation || isLoadingCharacteristic}
            isUninitialized={
              isUninitializedVariation && isUninitializedCharacteristic
            }
            isLoading={isLoadingVariation || isLoadingCharacteristic}
            isSuccess={
              (isSuccessVariations || isSuccessCharacteristic) &&
              !isLoadingVariation &&
              !isLoadingCharacteristic
            }
            isError={
              (isErrorVariation || isErrorCharacteristic) &&
              !isLoadingVariation &&
              !isLoadingCharacteristic
            }
            uninitializedText="Зберегти"
            successText="Успішно збережено"
            errorText="Не вдалось зберегти"
            errorMesage={
              (errorVariation
                ? (errorVariation as unknown as { data: { error: string } })
                    .data.error
                : "") ||
              (errorCharacteristic
                ? (
                    errorCharacteristic as unknown as {
                      data: { error: string };
                    }
                  ).data.error
                : "")
            }
          />
        </Form>
      )}
    </Formik>
  );
}
