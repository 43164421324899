import { createSlice } from "@reduxjs/toolkit";
import { ReferenceBookPageData } from "./interface";
import { SliceAction } from "store/store";
import ReferenceBookGroup, { ReferenceBook } from "@interfaces/refence-book";

const initialState: ReferenceBookPageData = {
    delete_books: [],
    edit_books: [],

    delete_group: -1,
    edit_group: null,

    delete_values: [],
    edit_values: [],
}

const referenceBookSlice = createSlice({
    initialState,
    name: "referenceBookPage",
    reducers: {
        referenceBook_deleteBooks_addData: (
            state,
            action: SliceAction<
                { id: ReferenceBook["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.delete_books = [
                ...state.delete_books,
                ...action.payload,
            ];
        },
        referenceBook_deleteBooks_setData: (
            state,
            action: SliceAction<
                { id: ReferenceBook["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.delete_books = action.payload;
        },
        referenceBook_deleteBooks_deleteData: (
            state,
            action: SliceAction<{ id: ReferenceBook["id"]; singleOnly: boolean }>,
        ) => {
            state.delete_books =
                state.delete_books.filter((item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                });
        },
        referenceBook_deleteBooks_clearData: (state) => {
            state.delete_books = [];
        },

        referenceBook_editBooks_addData: (
            state,
            action: SliceAction<
                {
                    id: ReferenceBook["id"];
                    name: ReferenceBook["name"];
                    group_id: ReferenceBookGroup["id"];
                    tag: "single" | "multiple";
                }[]
            >,
        ) => {
            state.edit_books = [
                ...state.edit_books,
                ...action.payload,
            ];
        },
        referenceBook_editBooks_setData: (
            state,
            action: SliceAction<
                {
                    id: ReferenceBook["id"];
                    name: ReferenceBook["name"];
                    group_id: ReferenceBookGroup["id"];
                    tag: "single" | "multiple";
                }[]
            >,
        ) => {
            state.edit_books = action.payload;
        },
        referenceBook_editBooks_deleteData: (
            state,
            action: SliceAction<{ id: ReferenceBook["id"]; singleOnly: boolean }>,
        ) => {
            state.edit_books = state.edit_books.filter(
                (item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                },
            );
        },
        referenceBook_editBooks_clearData: (state) => {
            state.edit_books = [];
        },

        referenceBook_deleteGroup_setData: (
            state,
            action: SliceAction<ReferenceBookGroup["id"]>,
        ) => {
            state.delete_group = action.payload;
        },
        referenceBook_deleteGroup_clearData: (state) => {
            state.delete_group = -1;
        },

        referenceBook_editGroup_setData: (
            state,
            action: SliceAction<Omit<ReferenceBookGroup, "items">>,
        ) => {
            state.edit_group = action.payload;
        },
        referenceBook_editGroup_clearData: (state) => {
            state.edit_group = null;
        },

        referenceBook_deleteValues_addData: (
            state,
            action: SliceAction<{ id: number; tag: "single" | "multiple" }[]>,
        ) => {
            state.delete_values = [
                ...state.delete_values,
                ...action.payload,
            ];
        },
        referenceBook_deleteValues_setData: (
            state,
            action: SliceAction<{ id: number; tag: "single" | "multiple" }[]>,
        ) => {
            state.delete_values = action.payload;
        },
        referenceBook_deleteValues_deleteData: (
            state,
            action: SliceAction<{ id: number; singleOnly: boolean }>,
        ) => {
            state.delete_values =
                state.delete_values.filter((item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                });
        },
        referenceBook_deleteValues_clearData: (state) => {
            state.delete_values = [];
        },

        referenceBook_editValues_addData: (
            state,
            action: SliceAction<
                {
                    id: number;
                    name: Record<string, string>;
                    tag: "single" | "multiple";
                }[]
            >,
        ) => {
            state.edit_values = [
                ...state.edit_values,
                ...action.payload,
            ];
        },
        referenceBook_editValues_setData: (
            state,
            action: SliceAction<
                {
                    id: number;
                    name: Record<string, string>;
                    tag: "single" | "multiple";
                }[]
            >,
        ) => {
            state.edit_values = action.payload;
        },
        referenceBook_editValues_deleteData: (
            state,
            action: SliceAction<{ id: number; singleOnly: boolean }>,
        ) => {
            state.edit_values =
                state.edit_values.filter((item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                });
        },
        referenceBook_editValues_clearData: (state) => {
            state.edit_values = [];
        },

        referenceBook_clearAllData: (state) => {
            state.delete_books = [];
            state.edit_books = [];

            state.delete_group = -1;
            state.edit_group = null;

            state.delete_values = [];
            state.edit_values = [];
        }
    }
})

const { actions, reducer } = referenceBookSlice;

export default reducer;

export const {
    referenceBook_deleteBooks_addData,
    referenceBook_deleteBooks_setData,
    referenceBook_deleteBooks_deleteData,
    referenceBook_deleteBooks_clearData,

    referenceBook_editBooks_addData,
    referenceBook_editBooks_setData,
    referenceBook_editBooks_deleteData,
    referenceBook_editBooks_clearData,

    referenceBook_deleteGroup_setData,
    referenceBook_deleteGroup_clearData,

    referenceBook_editGroup_setData,
    referenceBook_editGroup_clearData,

    referenceBook_deleteValues_addData,
    referenceBook_deleteValues_setData,
    referenceBook_deleteValues_deleteData,
    referenceBook_deleteValues_clearData,

    referenceBook_editValues_addData,
    referenceBook_editValues_setData,
    referenceBook_editValues_deleteData,
    referenceBook_editValues_clearData,
    
    referenceBook_clearAllData
} = actions;