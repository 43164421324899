import {
  setFixedMarkup,
  setPercentMarkup,
  switchUseFixedMarkup,
  switchUsePercentMarkup,
} from "@global-state/pages/unloading/slice";
import { Checkbox, InputNoFormik } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./FixedMarkup.module.scss";

export function FixedMarkup() {
  const { use_fixed_markup, fixed_markup, use_percent_markup, percent_markup } =
    useAppSelector((state) => state.unloadingPage.unloadingInfoObject);

  const dispatch = useAppDispatch();

  return (
    <div className={style["fixedMarkup"]}>
      <div className={style["switch"]}>
        <Checkbox
          checked={Boolean(use_fixed_markup)}
          onChange={() => {
            dispatch(switchUseFixedMarkup());
          }}
          label={
            <p className="text-[13px] leading-[15px] text-[#838383]">
              <span className="max-sm:hidden">Фіксована</span>{" "}
              <span className="hidden max-sm:inline">Фікс.</span> націнка у грн
            </p>
          }
        />
        <InputNoFormik
          defaultValue={fixed_markup ? String(fixed_markup) : ""}
          disabled={!use_fixed_markup}
          validate={(value) => value.replace(/\D/g, "")}
          setValue={(newValue) => {
            dispatch(setFixedMarkup(Number(newValue)));
          }}
        />
      </div>
      <div className={style["switch"]}>
        <Checkbox
          checked={Boolean(use_percent_markup)}
          onChange={() => {
            dispatch(switchUsePercentMarkup());
          }}
          label={
            <p className="text-[13px] leading-[15px] text-[#838383]">
              <span className="max-sm:hidden">Фіксована</span>{" "}
              <span className="hidden max-sm:inline">Фікс.</span> націнка у %
            </p>
          }
        />
        <InputNoFormik
          defaultValue={percent_markup ? String(percent_markup * 100) : ""}
          disabled={!use_percent_markup}
          validate={(value) => {
            const number = Number(value.replace(/\D/g, ""));

            if (number <= 0) {
              return "";
            } else if (number > 100) {
              return "100";
            } else {
              return String(number);
            }
          }}
          setValue={(newValue) => {
            dispatch(setPercentMarkup(Number(newValue) / 100));
          }}
        />
      </div>
    </div>
  );
}
