import ReferenceBookGroup from "@interfaces/refence-book";
import style from "./MenuBooksGroupsList.module.scss";
import { useReferenceBookAllQuery } from "@RTKQuery/reference-book/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Spinner } from "@ui";

export function MenuBooksGroupsList({
  children,
}: {
  children: (data: ReferenceBookGroup) => JSX.Element;
}) {
  const { group } = useParams();
  const { data, isFetching, isError } = useReferenceBookAllQuery();

  const navigate = useNavigate();
  const location = useLocation();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  useEffect(() => {
    if (data && data.length > 0) {
      const listIdGroup = data.reduce<number[]>(
        (arr, group) => [...arr, group.id],
        [],
      );

      if (!listIdGroup.includes(+(group || "-1"))) {
        navigate(crm + "/reference-book/" + data[0].id);
      }
    }
    if (data && data.length === 0) {
      if (
        location.pathname !== crm + "/reference-book/" &&
        location.pathname !== crm + "/reference-book"
      ) {
        navigate(crm + "/reference-book");
      }
    }
  }, [location, data]);

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодної групи.</p>
      </div>
    );
  } else {
    return (
      <nav className={style["menu"]}>{data.map((item) => children(item))}</nav>
    );
  }
}
