import { RxCrossCircled } from "react-icons/rx";
import style from "./Search.module.scss";

export function SearchInput({
  value,
  setValue,
}: {
  value: string;
  setValue: (newValue: string) => void;
}) {
  return (
    <div className={style["container"]}>
      <input
        className={style["input"]}
        type="text"
        placeholder={"Пошук..."}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <div className={style["clearButton"]}>
        {value ? (
          <RxCrossCircled
            className={style["icon"]}
            onClick={() => {
              setValue("");
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
