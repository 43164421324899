import cn from "classnames";
import style from "./AllProductsItem.module.scss";
import { ProductStock } from "@interfaces/stock";
import { EditableValue } from "./components";

export function AllProductsItem({ data }: { data: ProductStock }) {
  return (
    <div className={style["row"]}>
      <div className={cn(style["col"], "truncate pl-[10px]")} title={data.sku}>
        {data.sku}
      </div>
      <div className={style["col"]}>
        <div className="flex flex-wrap gap-[5px]">
          {data.variations.map((item, i) => (
            <span key={i} className="line-clamp-2 max-w-full">
              {item.name + ": " + item.value + ";"}
            </span>
          ))}
        </div>
      </div>
      <div className={cn(style["col"], "font-medium")}>
        <EditableValue data={data} />
      </div>
      <div className={cn(style["col"], "text-center font-medium")}>
        {data.total_qty} шт
      </div>
    </div>
  );
}
