import { Pagination, TableProduct, TopPanel } from "components/catalog/stocks";
import style from "./StockAllPage.module.scss";
import { MenuCategories } from "components/common/MenuCategories";

export function StockAllPage() {
  return (
    <main className={style["layout"]}>
      <div className={style["container"]}>
        <p className={style["currentPage"]}>Наявність</p>
        <MenuCategories
          pageName="stock"
          addRootCategoryBtn={false}
          addCategoryBtn={false}
          editCategoryBtn={false}
          removeCategoryBtn={false}
        />
        <TopPanel />
        <div className={style["tableBox"]}>
          <TableProduct />
          <Pagination />
        </div>
      </div>
    </main>
  );
}
