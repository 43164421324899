import {
  product_selected_variation_clearData,
  product_selected_variation_setData,
} from "@global-state/pages/products/slice";
import { ProductChildren, ProductExtended } from "@interfaces/product";
import { Checkbox } from "@ui";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllVariationsCheckbox({
  variationList,
}: {
  variationList: ProductChildren<ProductExtended>["children"] | undefined;
}) {
  const { productId } = useParams();

  const variationProcesDeleted = useAppSelector((state) => {
    return state.productsPage.product.selected_variation;
  }).childIds.filter((item) => item.tag === "multiple");

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={variationProcesDeleted.length === variationList?.length}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            product_selected_variation_setData({
              parentId: Number(productId || "-1"),
              childIds:
                variationList?.map((item) => ({
                  id: item.id,
                  tag: "multiple",
                })) || [],
            }),
          );
        } else {
          dispatch(product_selected_variation_clearData());
        }
      }}
    />
  );
}
