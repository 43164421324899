import { Variation } from "@interfaces/products-template";
import { VariationButtons, VariationCheckbox } from "./components";

import style from "./ListVariationsItem.module.scss";

export function ListVariationsItem({ variation }: { variation: Variation }) {
  return (
    <div className={style["variation"]}>
      <VariationCheckbox variation={variation} />
      <p className={style["value"]}>{variation.name}</p>
      <VariationButtons variation={variation} />
    </div>
  );
}
