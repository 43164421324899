import { useEffect, useState } from "react";
import cn from "classnames";
import style from "./HoroshopCategorySelect.module.scss";
import {
  useHoroshopCategoriesAllQuery,
  useHoroshopCategoryCreateMutation,
  useHoroshopCategoryUpdateMutation,
} from "@RTKQuery/horoshop-categories/api";
import { HoroshopCategory } from "@interfaces/unloading";
import { CrossSvg } from "./Cross.svg";
import { Button, Checkbox } from "@ui";

export function HoroshopCategorySelect({
  listValue,
  setListValue,
}: {
  listValue: string[];
  setListValue: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const [showlistHints, setShowlistHints] = useState<boolean>(false);
  const [valueInput, setValueInput] = useState<string>("");

  const { data: listHints } = useHoroshopCategoriesAllQuery();

  const [createHoroshopCategory] = useHoroshopCategoryCreateMutation();

  useEffect(() => {
    function hideHandler(e: any) {
      if (showlistHints) {
        if (!e.target.closest("[data-nohide]")) {
          setShowlistHints(false);
        }
      }
    }

    document.body.addEventListener("click", hideHandler);

    return () => {
      document.body.removeEventListener("click", hideHandler);
    };
  }, [showlistHints]);

  return (
    <div className="relative w-full">
      <p className={style["label"]}>Категорія товару</p>
      <input
        data-nohide
        className={cn(style["input"])}
        type="text"
        placeholder="Puma"
        value={valueInput}
        onChange={(e) => {
          setValueInput(e.target.value);
        }}
        onClick={() => {
          setShowlistHints(true);
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            e.preventDefault();
            if (valueInput) {
              setValueInput("");
              setListValue((state) => [...state, valueInput]);
              if (
                !(
                  listHints &&
                  listHints.findIndex((item) => item.name === valueInput) >= 0
                )
              ) {
                createHoroshopCategory(valueInput);
              }
            }
          }
        }}
      />
      <div className={style["listValues"]}>
        {listValue.map((curentItem, index: number) => (
          <div key={index} className={cn(style["value"], "p-[5px]")}>
            <span>{curentItem}</span>
            <Button
              type="delete"
              icon={(className, fill) => (
                <CrossSvg className={cn(className, fill)} />
              )}
              onClick={() => {
                setListValue((state) =>
                  state.filter((item) => item !== curentItem),
                );
              }}
            />
          </div>
        ))}
      </div>
      {listHints && listHints?.length > 0 ? (
        <div
          data-nohide
          className={cn(style["listHints"], showlistHints && "!block")}
        >
          <div className={style["scrollContainer"]}>
            {generateListHints(
              listHints.filter((item) =>
                item.name
                  .toLocaleLowerCase()
                  .includes(valueInput.toLocaleLowerCase()),
              ),
              listValue,
              setListValue,
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function generateListHints(
  listHints: HoroshopCategory[],
  listValue: string[],
  setListValue: React.Dispatch<React.SetStateAction<string[]>>,
) {
  const list: JSX.Element[] = [];

  listHints.forEach((curentItem) => {
    list.push(
      <ItemHints
        key={curentItem.id}
        data={curentItem}
        listValue={listValue}
        setListValue={setListValue}
      />,
    );
    list.push(
      <div
        key={"divider" + curentItem.id}
        className={style["dividerHints"]}
      ></div>,
    );
  });

  return list;
}

function ItemHints({
  data,
  listValue,
  setListValue,
}: {
  data: HoroshopCategory;
  listValue: string[];
  setListValue: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const [updateHoroshopCategory] = useHoroshopCategoryUpdateMutation();

  const [editMode, setEditMode] = useState<string>("");

  return (
    <div className={style["itemHints"]}>
      <div className="flex gap-[13px]">
        {editMode ? (
          <input
            type="text"
            className={style["editModeInput"]}
            defaultValue={data.name}
            value={editMode}
            onChange={(e) => {
              setEditMode(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                if (editMode) {
                  updateHoroshopCategory({
                    id: data.id,
                    name: editMode,
                  });
                  setEditMode("");
                }
              }
            }}
            onBlur={() => {
              if (editMode) {
                updateHoroshopCategory({
                  id: data.id,
                  name: editMode,
                });
                setEditMode("");
              }
            }}
          />
        ) : (
          <>
            <Checkbox
              checked={listValue.includes(data.name)}
              onChange={(e) => {
                if (e.target.checked) {
                  setListValue((state) => [...state, data.name]);
                } else {
                  setListValue((state) =>
                    state.filter((item) => item !== data.name),
                  );
                }
              }}
            />
            <p>{data.name}</p>
          </>
        )}
      </div>
      <Button
        type="edit"
        onClick={() => {
          if (editMode) {
            setEditMode("");
          } else {
            setEditMode(data.name);
          }
        }}
      />
    </div>
  );
}
