import { ProductTable } from "@interfaces/product";
import { useProductsAllQuery } from "@RTKQuery/products/api";
import { Spinner } from "@ui";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import style from "./TableProductBody.module.scss";

export function TableProductBody({
  sortBy,
  order,
  children,
}: {
  sortBy: string;
  order: string;
  children: (data: ProductTable) => JSX.Element;
}) {
  const { categoryId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();

  const { data, isFetching, isError } = useProductsAllQuery({
    category_id: +(categoryId || "0"),
    limit: +(queryParams.get("limit") || "20"),
    offset: +(queryParams.get("offset") || "0"),
    search: queryParams.get("search") || undefined,
  });

  useEffect(() => {
    function chekLocalStorage() {
      if (
        !window.localStorage.getItem("searchStockPage") ||
        window.localStorage.getItem("searchStockPage") === ""
      ) {
        return null;
      } else {
        return true;
      }
    }

    if (queryParams.size === 0) {
      setQueryParams(
        Object.assign(
          {
            limit:
              queryParams.get("limit") ||
              window.localStorage.getItem("limitStockPage") ||
              "20",
            offset:
              queryParams.get("offset") ||
              window.localStorage.getItem("offsetStockPage") ||
              "0",
          },
          chekLocalStorage() && {
            search:
              queryParams.get("search") ||
              window.localStorage.getItem("searchStockPage") ||
              "",
          },
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (data?.products.length === 0 && queryParams.get("offset") + "" !== "0") {
      setQueryParams(
        Object.assign(
          {
            limit:
              queryParams.get("limit") ||
              window.localStorage.getItem("limitStockPage") ||
              "20",
            offset: "0",
          },
          queryParams.get("search") && {
            search:
              queryParams.get("search") ||
              window.localStorage.getItem("searchStockPage") ||
              "",
          },
        ),
      );
    }
  }, [data]);

  useEffect(() => {
    window.localStorage.setItem(
      "limitStockPage",
      queryParams.get("limit") || "20",
    );
    window.localStorage.setItem(
      "offsetStockPage",
      queryParams.get("offset") || "0",
    );
    window.localStorage.setItem(
      "searchStockPage",
      queryParams.get("search") || "",
    );
  }, [queryParams]);

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!data || data.products.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодного товару.</p>
      </div>
    );
  } else {
    return (
      <>
        {data.products
          .slice()
          .sort((a, b) => {
            if (sortBy === "buying_price") {
              return sort(a.buying_price, b.buying_price, order);
            } else if (sortBy === "ua_name") {
              return sort(a.ua_name, b.ua_name, order);
            } else if (sortBy === "retail_price") {
              return sort(a.retail_price, b.retail_price, order);
            } else {
              return 0;
            }
          })
          .map((item) => children(item))}
      </>
    );
  }
}

function sort<T>(a: T, b: T, order: string) {
  if (typeof a === "string" && typeof b === "string") {
    return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  } else if (typeof a === "number" && typeof b === "number") {
    return order === "asc" ? a - b : b - a;
  } else {
    return 0;
  }
}
