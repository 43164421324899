import { AddParameterForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { useCharacteristicsCreateMutation } from "@RTKQuery/characteristics/api";
import { useVariationsCreateMutation } from "@RTKQuery/variations/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "AddParameterModal";

export function AddParameterModal() {
  const [
    _,
    { isSuccess: isSuccessCharacteristic, reset: resetCharacteristic },
  ] = useCharacteristicsCreateMutation({
    fixedCacheKey: "add-characteristic",
  });

  const [__, { isSuccess: isSuccessVariation, reset: resetVariation }] =
    useVariationsCreateMutation({
      fixedCacheKey: "add-variation",
    });

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccessCharacteristic || isSuccessVariation) {
      if (!activeModal) {
        resetCharacteristic();
        resetVariation();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccessCharacteristic, isSuccessVariation, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title="Додати новий параметр"
    >
      <AddParameterForm />
    </CRMModalLayout>
  );
}
