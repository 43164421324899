import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import { RiLoader5Fill } from "react-icons/ri";
import cn from "classnames";
import style from "./AccountMainLayout.module.scss";
import { axiosLog } from "@api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CreateTenantModal, DeleteTenantModal } from "@modals";
import { clearSuperadminAll } from "@global-state/entity/superadmin/slice";

export function AccountMainLayout() {
  const superadmin = useAppSelector((state) => state.superadmin.user);

  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const dispatch = useAppDispatch();

  function logout() {
    setSubmitingStatus("load");

    axiosLog("https://account-api.levelup.com.ua")
      .post(
        "/auth/logout",
        {
          access_token: window.localStorage.getItem("access_token") || "",
          refresh_token: window.localStorage.getItem("refresh_token") || "",
        },
        {
          headers: {
            Authorization: window.localStorage.getItem("access_token") || "",
          },
        },
      )
      .then(() => {
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("refresh_token");
        setSubmitingStatus("success");
        dispatch(clearSuperadminAll());
      })
      .catch((err) => {
        setSubmitingStatus({
          status: "failure",
          errorText: err?.response?.data?.error || "Непередбачувана помилка",
        });
        console.error(err);
      });
  }

  return (
    <div className={style["container"]}>
      <div className={style["limit"]}>
        <div className={style["topPanel"]}>
          <Link
            to={
              process.env.NODE_ENV === "development"
                ? "/account/dashboard"
                : "/dashboard"
            }
          >
            <div className={style["logo"]}>
              <div className={style["imageLimit"]}>
                <img
                  src="/images/logoMain.svg"
                  alt="logo"
                  className={style["image"]}
                />
              </div>
              <p className={style["label"]}>LevelUp</p>
            </div>
          </Link>
          <div className={style["actions"]}>
            <Link
              to={
                process.env.NODE_ENV === "development"
                  ? "/account/settings"
                  : "/settings"
              }
              className={cn(style["emailLink"], "custom-responsive-email")}
            >
              {superadmin.email}
            </Link>
            <button
              className={cn(
                style["logoutButton"],
                submitingStatus === "idle" && style["uninitialized"],
                submitingStatus === "load" && style["loading"],
                submitingStatus === "success" && style["success"],
                typeof submitingStatus === "object" && style["error"],
              )}
              disabled={submitingStatus === "load"}
              onClick={logout}
            >
              {submitingStatus === "load" ? (
                <div className={style["loader"]}>
                  <RiLoader5Fill className={style["icon"]} />
                </div>
              ) : (
                <LogoutIcon className={style["logoutIcon"]} />
              )}
              <div className={style["errorMessage"]}>
                {typeof submitingStatus === "object" ? (
                  <p className={style["errorText"]}>
                    {submitingStatus.errorText}
                  </p>
                ) : null}
              </div>
            </button>
          </div>
        </div>
        <Outlet />
      </div>
      <CreateTenantModal />
      <DeleteTenantModal />
    </div>
  );
}

function LogoutIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
    >
      <path
        d="M14.3333 2.40934H17.4103C17.9543 2.40934 18.476 2.63561 18.8607 3.03838C19.2454 3.44114 19.4615 3.98741 19.4615 4.557V5.63084M14.3333 19.5907H17.4103C17.9543 19.5907 18.476 19.3644 18.8607 18.9616C19.2454 18.5589 19.4615 18.0126 19.4615 17.443V16.3692M7.15487 11H7.15385M14.3333 11H23.5M23.5 11L21 9M23.5 11L21 13M2.46154 18.9764L8.61539 20.9093C8.92173 21.0056 9.24533 21.0255 9.56034 20.9676C9.87535 20.9096 10.173 20.7753 10.4297 20.5754C10.6863 20.3754 10.8947 20.1155 11.0382 19.8162C11.1818 19.517 11.2565 19.1867 11.2564 18.8519V3.14814C11.2565 2.81326 11.1818 2.48301 11.0382 2.18376C10.8947 1.88451 10.6863 1.62455 10.4297 1.42465C10.173 1.22474 9.87535 1.09043 9.56034 1.03245C9.24533 0.974457 8.92173 0.994397 8.61539 1.09067L2.46154 3.02357C2.039 3.15637 1.6686 3.42825 1.40532 3.79888C1.14204 4.16951 0.999903 4.61914 1 5.08103V16.919C0.999903 17.3809 1.14204 17.8305 1.40532 18.2011C1.6686 18.5717 2.039 18.8436 2.46154 18.9764Z"
        stroke="inherit"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
