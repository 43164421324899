import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "store/store";
import { ProductsPageData } from "./interface";
import { ProductExtended, ProductTable } from "@interfaces/product";
import Category from "@interfaces/category";

const initialState: ProductsPageData = {
    category: {
        add: -1,
        delete: null,
        edit: null,
    },

    product: {
        delete: [],
        selected_variation: {
            childIds: [],
            parentId: -1,
        },
    },
}

const productsSlice = createSlice({
    initialState,
    name: "productsPage",
    reducers: {
        category_add_setData: (
            state,
            action: SliceAction<Category["id"] | null>,
        ) => {
            state.category.add = action.payload;
        },
        category_delete_setData: (state, action: SliceAction<Category>) => {
            state.category.delete = action.payload;
        },
        category_edit_setData: (state, action: SliceAction<Category>) => {
            state.category.edit = action.payload;
        },

        product_delete_addData: (
            state,
            action: SliceAction<
                { id: ProductTable["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.product.delete = [...state.product.delete, ...action.payload];
        },
        product_delete_setData: (
            state,
            action: SliceAction<
                { id: ProductTable["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.product.delete = action.payload;
        },
        product_delete_deleteData: (
            state,
            action: SliceAction<{ id: ProductTable["id"]; singleOnly: boolean }>,
        ) => {
            state.product.delete = state.product.delete.filter((item) => {
                if (action.payload.singleOnly) {
                    return item.id !== action.payload.id || item.tag !== "single";
                } else {
                    return item.id !== action.payload.id;
                }
            });
        },
        product_delete_clearData: (state) => {
            state.product.delete = [];
        },

        product_selected_variation_addData: (
            state,
            action: SliceAction<{
                childIds: { id: ProductExtended["id"], tag: "single" | "multiple" }[];
                parentId: ProductExtended["id"];
            }>,
        ) => {
            state.product.selected_variation = {
                parentId: action.payload.parentId,
                childIds: [...state.product.selected_variation.childIds, ...action.payload.childIds]
            };
        },
        product_selected_variation_deleteData: (state, action: SliceAction<{ id: ProductExtended["id"]; singleOnly: boolean }>) => {
            state.product.selected_variation.childIds = state.product.selected_variation.childIds.filter((item) => {
                if (action.payload.singleOnly) {
                    return item.id !== action.payload.id || item.tag !== "single";
                } else {
                    return item.id !== action.payload.id;
                }
            });
        },
        product_selected_variation_setData: (
            state,
            action: SliceAction<{
                childIds: { id: ProductExtended["id"], tag: "single" | "multiple" }[];
                parentId: ProductExtended["id"];
            }>,
        ) => {
            state.product.selected_variation = action.payload;
        },
        product_selected_variation_clearData: (state) => {
            state.product.selected_variation = {
                childIds: [],
                parentId: -1,
            }
        },

        products_clearAllData: (state) => {
            state.category = {
                add: -1,
                delete: null,
                edit: null,
            };

            state.product = {
                delete: [],
                selected_variation: {
                    childIds: [],
                    parentId: -1,
                },
            };
        }
    }
})

const { reducer, actions } = productsSlice;

export default reducer;

export const {
    category_add_setData,
    category_delete_setData,
    category_edit_setData,

    product_delete_addData,
    product_delete_setData,
    product_delete_deleteData,
    product_delete_clearData,

    product_selected_variation_setData,
    product_selected_variation_addData,
    product_selected_variation_deleteData,
    product_selected_variation_clearData,

    products_clearAllData
} = actions;