import { Link, useSearchParams } from "react-router-dom";

import style from "./TopPanel.module.scss";
import { Button, Input, SearchInputProps } from "@ui";

export function TopPanel() {
  const [_, setQueryParams] = useSearchParams();

  return (
    <div className={style["topPanel"]}>
      <div className={style["search"]}>
        <Input<SearchInputProps>
          inputType="search"
          placeholder="Пошук..."
          onSearch={(value) => {
            setQueryParams((params) =>
              Object.assign(
                {
                  limit: params.get("limit") || "20",
                  offset: params.get("offset") || "0",
                },
                value && {
                  search: value,
                },
              ),
            );
          }}
        />
      </div>
      <div className={style["button"]}>
        <Link to={"product/add"}>
          <Button
            type="add"
            size="big"
            text="Додати новий товар"
            onClick={() => {}}
            className={style["custom"]}
          />
        </Link>
      </div>
    </div>
  );
}
