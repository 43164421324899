import { createSlice } from "@reduxjs/toolkit";
import { TenantPageData } from "./interface";
import { SliceAction } from "store/store";
import Tenant from "@interfaces/tenant";

const initialState: TenantPageData = {
    delete: null
}

const tenantSlice = createSlice({
    initialState,
    name: "tenantPage",
    reducers: {
        tenant_delete_setData: (state, action: SliceAction<Tenant>) => {
            state.delete = action.payload;
        },
        tenant_delete_clearData: (state) => {
            state.delete = null;
        },
    }
})

const { reducer, actions } = tenantSlice;

export default reducer;

export const {
    tenant_delete_clearData,
    tenant_delete_setData
} = actions;