import { useParams } from "react-router-dom";
import style from "./CurrentPage.module.scss";
import { useReferenceBookAllQuery } from "@RTKQuery/reference-book/api";
import { BackButton } from "@ui";

export function CurrentPage() {
  const { data } = useReferenceBookAllQuery();

  const { group: groupId, book: bookId } = useParams();

  const group = data?.find((item) => item.id === Number(groupId));

  const book = group?.items?.find((item) => item.id === Number(bookId));

  if (book) {
    return (
      <div className={style["container"]}>
        <BackButton url={"./" + groupId} />
        <p className={style["currentPage"]}>{book.name}</p>
      </div>
    );
  } else {
    return (
      <div className={style["container"]}>
        <p className={style["currentPage"]}>Довідники</p>
      </div>
    );
  }
}
