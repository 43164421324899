import { Variation } from "@interfaces/products-template";
import { useProductsTemplateOneQuery } from "@RTKQuery/products-template/api";
import { useParams } from "react-router-dom";
import style from "./ListVariationsBody.module.scss";
import { Spinner } from "@ui";

export function ListVariationsBody({
  children,
}: {
  children: (data: Variation) => JSX.Element;
}) {
  const { id: idTemplate } = useParams();

  const { data, isFetching, isError } = useProductsTemplateOneQuery(
    Number(idTemplate),
  );

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else if (!data || data.variations.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодної варіації.</p>
      </div>
    );
  } else {
    return (
      <div className={style["listBody"]}>
        {data.variations.map((item) => children(item))}
      </div>
    );
  }
}
