import { ProductProvider } from "@interfaces/stock";
import style from "./TableProductsProvidersItem.module.scss";
import cn from "classnames";
import { VariationsItem, VariationsList } from "./components";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setAddProductProviderSelectData } from "@global-state/pages/stock/slice";

export function TableProductsProvidersItem({
  data,
}: {
  data: ProductProvider;
}) {
  const addProductProviderSelectData = useAppSelector(
    (state) => state.stockPage.addProductProviderSelectData,
  );

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn(
        style["item"],
        addProductProviderSelectData.product_id === data.id &&
          style["item_active"],
      )}
      onClick={() => {
        dispatch(
          setAddProductProviderSelectData({
            product_id: data.id,
            supplier_id: data.supplier_id,
          }),
        );
      }}
    >
      <div className={style["mainInfo"]}>
        <div className={cn(style["bodyColumn"], "justify-self-center")}>
          <div className={style["imageLimit"]}>
            <img src={data.picture} alt={data.name} className={style["img"]} />
          </div>
        </div>
        <div className={style["bodyColumn"]} title={data.supplier}>
          <p className="truncate">{data.supplier}</p>
        </div>
        <div className={style["bodyColumn"]} title={data.sku}>
          <p className="truncate">{data.sku}</p>
        </div>
        <div className={style["bodyColumn"]} title={data.name}>
          <p className="truncate">{data.name}</p>
        </div>
        <div className={style["bodyColumn"]} title={String(data.drop_price)}>
          <p className="truncate font-medium">{data.drop_price} грн.</p>
        </div>
      </div>
      <div className={style["variations"]}>
        <VariationsList data={data.children}>
          {(data, index) => <VariationsItem key={data.id + index} data={data} />}
        </VariationsList>
      </div>
    </div>
  );
}
