import { createSlice } from "@reduxjs/toolkit";
import { ProductsTemplatePageData } from "./interface";
import { SliceAction } from "store/store";
import ProductsTemplate, { Parameter } from "@interfaces/products-template";

const initialState: ProductsTemplatePageData = {
    products_template: {
        delete: [],
        edit: null,
    },

    parameters: {
        delete_characteristic: [],
        delete_variation: [],
        edit: null,
    },
}

const productsTemplateSlice = createSlice({
    initialState,
    name: "productsTemplatePage",
    reducers: {
        productsTemplate_delete_addData: (
            state,
            action: SliceAction<
                { id: ProductsTemplate["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.products_template.delete = [
                ...state.products_template.delete,
                ...action.payload,
            ];
        },
        productsTemplate_delete_setData: (
            state,
            action: SliceAction<
                { id: ProductsTemplate["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.products_template.delete = action.payload;
        },
        productsTemplate_delete_deleteData: (
            state,
            action: SliceAction<{ id: ProductsTemplate["id"]; singleOnly: boolean }>,
        ) => {
            state.products_template.delete = state.products_template.delete.filter(
                (item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                },
            );
        },
        productsTemplate_delete_clearData: (state) => {
            state.products_template.delete = [];
        },

        productsTemplate_edit_setData: (
            state,
            action: SliceAction<Pick<ProductsTemplate, "id" | "name">>,
        ) => {
            state.products_template.edit = action.payload;
        },
        productsTemplate_edit_clearData: (state) => {
            state.products_template.edit = null;
        },

        parameters_delete_addData: (
            state,
            action: SliceAction<{
                data: { id: Parameter["id"]; tag: "single" | "multiple" }[];
                parameterType: "characteristic" | "variation";
            }>,
        ) => {
            if (action.payload.parameterType === "characteristic") {
                state.parameters.delete_characteristic = [
                    ...state.parameters.delete_characteristic,
                    ...action.payload.data,
                ];
            } else {
                state.parameters.delete_variation = [
                    ...state.parameters.delete_variation,
                    ...action.payload.data,
                ];
            }
        },
        parameters_delete_setData: (
            state,
            action: SliceAction<{
                data: { id: Parameter["id"]; tag: "single" | "multiple" }[];
                parameterType: "characteristic" | "variation";
            }>,
        ) => {
            if (action.payload.parameterType === "characteristic") {
                state.parameters.delete_characteristic = action.payload.data;
            } else {
                state.parameters.delete_variation = action.payload.data;
            }
        },
        parameters_delete_deleteData: (
            state,
            action: SliceAction<{
                data: { id: Parameter["id"]; singleOnly: boolean };
                parameterType: "characteristic" | "variation";
            }>,
        ) => {
            if (action.payload.parameterType === "characteristic") {
                state.parameters.delete_characteristic =
                    state.parameters.delete_characteristic.filter((item) => {
                        if (action.payload.data.singleOnly) {
                            return (
                                item.id !== action.payload.data.id || item.tag !== "single"
                            );
                        } else {
                            return item.id !== action.payload.data.id;
                        }
                    });
            } else {
                state.parameters.delete_variation =
                    state.parameters.delete_variation.filter((item) => {
                        if (action.payload.data.singleOnly) {
                            return (
                                item.id !== action.payload.data.id || item.tag !== "single"
                            );
                        } else {
                            return item.id !== action.payload.data.id;
                        }
                    });
            }
        },
        parameters_delete_clearData: (
            state,
            action: SliceAction<"characteristic" | "variation">,
        ) => {
            if (action.payload === "characteristic") {
                state.parameters.delete_characteristic = [];
            } else {
                state.parameters.delete_variation = [];
            }
        },

        parameters_edit_setData: (state, action: SliceAction<Parameter>) => {
            state.parameters.edit = action.payload;
        },
        parameters_edit_clearData: (state) => {
            state.parameters.edit = null;
        },

        productsTemplate_clearAllData: (state) => {
            state.products_template = {
                delete: [],
                edit: null,
            };

            state.parameters = {
                delete_characteristic: [],
                delete_variation: [],
                edit: null,
            };
        }
    }
})

const { actions, reducer } = productsTemplateSlice;

export default reducer;

export const {
    productsTemplate_delete_addData,
    productsTemplate_delete_setData,
    productsTemplate_delete_deleteData,
    productsTemplate_delete_clearData,

    productsTemplate_edit_setData,
    productsTemplate_edit_clearData,

    parameters_delete_addData,
    parameters_delete_setData,
    parameters_delete_deleteData,
    parameters_delete_clearData,

    parameters_edit_setData,
    parameters_edit_clearData,

    productsTemplate_clearAllData
} = actions;