import { Form, Formik } from "formik";
import * as Yup from "yup";

import style from "./ResetPasswordForm.module.scss";
import { useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { axiosLog } from "@api";
import { setSuperadminAll } from "@global-state/entity/superadmin/slice";
import { setSuperadminAllDTO } from "@global-state/entity/superadmin/dto/setSuperadminAll.dto";
import { Input, PasswordInputProps, SubmitButton } from "@ui";

export function ResetPasswordForm() {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | "success" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const [searchParams] = useSearchParams();
  const { token } = useParams();

  const dispatch = useAppDispatch();
  const passwordRef = useRef<string>("");

  return (
    <Formik
      initialValues={{
        password: "",
        repeatPassword: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Пароль - обов'язкове поле"),
        repeatPassword: Yup.string()
          .test(
            "is-equal",
            "Паролі не співпадають",
            (value, context) => value === passwordRef.current,
          )
          .required("Підтвердження нового паролю - обов'язкове поле"),
      })}
      onSubmit={(values) => {
        setSubmitingStatus("load");

        axiosLog("https://account-api.levelup.com.ua")
          .post(
            "/auth/reset-password",
            {
              email: searchParams.get("email"),
              password: values.password,
              token: token,
            },
            {
              withCredentials: true,
            },
          )
          .then((response) => {
            if (response.data.ok) {
              window.localStorage.setItem(
                "access_token",
                response.data.access_token,
              );
              window.localStorage.setItem(
                "refresh_token",
                response.data.refresh_token,
              );
              setSubmitingStatus("success");

              dispatch(setSuperadminAll(setSuperadminAllDTO(response.data)));
            }
          })
          .catch((err) => {
            setSubmitingStatus({
              status: "failure",
              errorText:
                err?.response?.data?.error || "Непередбачувана помилка",
            });
            console.error(err);

            setTimeout(() => {
              setSubmitingStatus("idle");
            }, 10000);
          });
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input<PasswordInputProps>
            formikName="password"
            label="Новий пароль"
            inputType="password"
            passwordRef={passwordRef}
          />
          <Input<PasswordInputProps>
            formikName="repeatPassword"
            label="Підтвердження нового паролю"
            inputType="password"
          />
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={submitingStatus === "load"}
            isUninitialized={submitingStatus === "idle"}
            isLoading={submitingStatus === "load"}
            isSuccess={submitingStatus === "success"}
            isError={typeof submitingStatus === "object"}
            uninitializedText="Зберегти"
            successText="Успішно збережено"
            errorText="Не вдалось зберегти"
            errorMesage={
              typeof submitingStatus === "object"
                ? submitingStatus.errorText
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
