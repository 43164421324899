import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { accountMainRouterConfig } from "./routers/account/main.router";
import { accountAuthRouterConfig } from "./routers/account/auth.router";
import { CRMMainRouterConfig } from "./routers/crm/main.router";
import { CRMAuthRouterConfig } from "./routers/crm/auth.router";
import { LoadingBackdrop } from "./components/common/LoadingBackdrop";
import { axiosAuth } from "@api";
import {
  clearSuperadminAll,
  setSuperadminAll,
} from "@global-state/entity/superadmin/slice";
import { setSuperadminAllDTO } from "@global-state/entity/superadmin/dto/setSuperadminAll.dto";
import {
  clearCRMUserAll,
  setCRMUserAll,
} from "@global-state/entity/CRMUser/slice";
import { setCRMUserAllDTO } from "@global-state/entity/CRMUser/dto/SetCRMUserAll.dto";

export default function App() {
  const [loadData, setLoadData] = useState<boolean>(true);

  const superadminId = useAppSelector((state) => state.superadmin.user.uuid);
  const CRMUserId = useAppSelector((state) => state.CRMUser.user.uuid);

  const rootPath =
    process.env.NODE_ENV === "development"
      ? window.location.pathname.split("/")[1] || "account"
      : window.location.host.split(".")[0];

  window.localStorage.setItem("subdomain", rootPath);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rootPath === "account" || rootPath === "") {
      if (window.localStorage.getItem("access_token")) {
        axiosAuth("https://account-api.levelup.com.ua")
          .get("/account")
          .then((response) => {
            if (response.data.ok) {
              dispatch(setSuperadminAll(setSuperadminAllDTO(response.data)));
            }
          })
          .catch((err) => {
            if (err === "logaut") {
              console.log(err);
              dispatch(clearSuperadminAll());
            }
          })
          .finally(() => {
            setLoadData(false);
          });
      } else {
        setLoadData(false);
      }
    } else {
      if (window.localStorage.getItem("access_token_crm")) {
        axiosAuth(
          `https://${rootPath}.api.levelup.com.ua/dropers`,
          "access_token_crm",
          "refresh_token_crm",
        )
          .get("/auth/profile")
          .then((response) => {
            if (response.data.ok || true) {
              dispatch(
                setCRMUserAll(setCRMUserAllDTO({ profile: response.data })),
              );
            }
          })
          .catch((err) => {
            setLoadData(false);

            if (err === "logaut") {
              console.log(err);
              dispatch(clearCRMUserAll());
            }
          })
          .finally(() => {
            setLoadData(false);
          });
      } else {
        setLoadData(false);
      }
    }
  }, []);

  let currentRouterConfig = null;

  if (rootPath === "account" || rootPath === "") {
    if (superadminId) {
      currentRouterConfig = accountMainRouterConfig;
    } else {
      currentRouterConfig = accountAuthRouterConfig;
    }
  } else {
    if (CRMUserId) {
      currentRouterConfig = CRMMainRouterConfig(rootPath);
    } else {
      currentRouterConfig = CRMAuthRouterConfig(rootPath);
    }
  }

  document.body.classList.add(
    "bg-backdrop-main-light",
    "dark:bg-backdrop-main-dark",
  );

  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
    window.localStorage.setItem("theme", "dark");
  } else {
    document.documentElement.classList.remove("dark");
    window.localStorage.setItem("theme", "light");
  }

  if (loadData) {
    return <LoadingBackdrop />;
  } else {
    return <RouterProvider router={createBrowserRouter(currentRouterConfig)} />;
  }
}
