import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActionsParameters.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";
import cn from "classnames";

export function MultipleActionsParameters() {
  const delete_characteristic = useAppSelector(
    (state) => state.productsTemplatePage.parameters.delete_characteristic,
  );
  const delete_variation = useAppSelector(
    (state) => state.productsTemplatePage.parameters.delete_variation,
  );

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn(
        style["multipleActions"],
        [...delete_characteristic, ...delete_variation].some(
          (item) => item.tag === "multiple",
        ) && "h-[50px]",
      )}
    >
      <div className={style["button"]}>
        <Button
          type="delete"
          size="big"
          text="Видалити"
          onClick={() => {
            dispatch(
              showModal({
                name: "DeleteParameterModal",
                mode: "multiple",
              }),
            );
          }}
        />
      </div>
    </div>
  );
}
