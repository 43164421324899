import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import style from "./CreateTenantModal.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CreateTenantForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";

const MODAL_NAME = "CreateTenantModal";

export function CreateTenantModal() {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const activeModal = useAppSelector((state) => state.modalState.modalName);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess && activeModal === MODAL_NAME) {
      refSetTimeout.current = setTimeout(() => {
        hiddenModal();
      }, 700);
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);
    setIsSuccess(false);

    dispatch(hideModal());
  }

  return (
    <div
      className={cn(style["backdrop"], activeModal === MODAL_NAME && "flex")}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          hiddenModal();
        }
      }}
    >
      <div className={style["modalContent"]}>
        <p className={style["title"]}>Нова CRM</p>
        <p className={style["message"]}>
          Створіть адресу входу в KeyCRM для вашої компанії
        </p>
        <CreateTenantForm setIsSuccess={setIsSuccess} />
      </div>
    </div>
  );
}
