import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useEffect } from "react";
import { useCategoryCreateMutation } from "@RTKQuery/categories/api";
import { useAppSelector } from "store/hooks";
import { SubmitButton, Input } from "@ui";

const ValidationSchema = Yup.object().shape({
  nameUA: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва UA - обов'язкове поле"),
  nameRU: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва RU - обов'язкове поле"),
});

export function AddCategoryForm() {
  const [
    createCategory,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useCategoryCreateMutation({
    fixedCacheKey: "add-category",
  });

  const category_add = useAppSelector(
    (state) => state.productsPage.category.add,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nameUA: "",
        nameRU: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        createCategory({
          parent_id: category_add,
          name: {
            uk: values.nameUA,
            ru: values.nameRU,
          },
        })
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => {});
      }}
    >
      <Form>
        <div className="mb-[40px]">
          <div className="flex w-full gap-[15px] max-sm:flex-col max-sm:gap-[5px]">
            <div className="w-[50%] max-sm:w-[100%]">
              <Input formikName="nameUA" label="Назва UA" inputType="text" />
            </div>
            <div className="w-[50%] max-sm:w-[100%]">
              <Input formikName="nameRU" label="Назва RU" inputType="text" />
            </div>
          </div>
        </div>
        <SubmitButton
          type="submit"
          disabled={isLoading}
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={
            error
              ? (error as unknown as { data: { error: string } }).data.error
              : ""
          }
        />
      </Form>
    </Formik>
  );
}
