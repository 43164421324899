import { UnloadingLink } from "@interfaces/unloading";
import style from "./UnloadingsItem.module.scss";
import { Button } from "@ui";
import { CopySvg } from "./Copy.svg";
import { DownloadSvg } from "./Download.svg";
import { DotsSvg } from "./Dots.svg";
import { useEffect, useRef, useState } from "react";
import {
  DeleteIcon,
  EditIcon,
} from "components/common/UI/buttons/Button/components/icons";
import { Link, useParams } from "react-router-dom";
import { showModal } from "@global-state/entity/modal-state/slice";
import { useAppDispatch } from "store/hooks";
import { createPortal } from "react-dom";
import { setSelectUnloading } from "@global-state/pages/unloading/slice";

export function UnloadingsItem({ data }: { data: UnloadingLink }) {
  const [successClickBoardCopy, setSuccessClickBoardCopy] =
    useState<boolean>(false);
  const [showDotsMenu, setShowDotsMenu] = useState<boolean>(false);

  const opener = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClick(e: any) {
      if (!e.target.closest(`[data-opener]`)) {
        setShowDotsMenu(false);
      } else {
        if (
          !(
            e.target.closest(`[data-opener]`).dataset.opener === String(data.id)
          )
        ) {
          setShowDotsMenu(false);
        }
      }
    }

    function handleWheel(e: any) {
      if (e.deltaY !== 0) {
        setShowDotsMenu(false);
      }
    }

    document.addEventListener("wheel", handleWheel);
    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div className={style["item"]}>
      <div className={style["name"]}>
        <p className="truncate">{data.name}</p>
      </div>
      <div className={style["fileFormat"]}>
        .{data.file_format.toLowerCase()}
      </div>
      <div className="h-[40px] w-[270px] shrink-0 max-sm:order-4 max-sm:w-full">
        <Button
          size="big"
          variant="outlined"
          text="Скопіювати посилання"
          icon={(className) => <CopySvg className={className} />}
          alignIcon="right"
          data-link={data.link}
          color={successClickBoardCopy ? "success" : "default"}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (e.currentTarget.dataset.link) {
              navigator.clipboard
                .writeText(e.currentTarget.dataset.link)
                .then(() => {
                  setSuccessClickBoardCopy(true);
                  setTimeout(() => {
                    setSuccessClickBoardCopy(false);
                  }, 2400);
                });
            }
          }}
        />
      </div>
      <a
        href={data.link}
        className="h-[40px] w-[200px] shrink-0 max-sm:order-5 max-sm:w-full"
      >
        <Button
          size="big"
          variant="outlined"
          text="Завантажити"
          icon={(className) => <DownloadSvg className={className} />}
          alignIcon="right"
          onClick={() => {}}
        />
      </a>
      <div className={style["dotsButton"]}>
        <div className="h-full" ref={opener}>
          <Button
            size="big"
            variant="outlined"
            icon={(className) => <DotsSvg className={className} />}
            alignIcon="right"
            data-opener={data.id}
            onClick={() => {
              setShowDotsMenu((state) => !state);
            }}
          />
        </div>
        {showDotsMenu && (
          <RelativePortal>
            <DotsMenu
              data={data}
              x={(opener.current?.getBoundingClientRect().x || 0) - 260}
              y={
                (opener.current?.getBoundingClientRect().y || 0) +
                52 +
                (window.scrollY || window.pageYOffset)
              }
            />
          </RelativePortal>
        )}
      </div>
    </div>
  );
}

function RelativePortal({ children }: { children: JSX.Element }) {
  const node = document.createElement("div");

  document.body.appendChild(node);

  useEffect(() => {
    return () => {
      document.body.removeChild(node);
    };
  });

  return createPortal(children, node);
}

function DotsMenu({
  data,
  x,
  y,
}: {
  data: UnloadingLink;
  x: number;
  y: number;
}) {
  const { marketplaceId } = useParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  const dispatch = useAppDispatch();

  const overflowY = y + 120 - window.innerHeight;

  return (
    <div
      className="dotsMenu"
      style={{
        top:
          y + 120 > window.innerHeight
            ? y - (window.innerWidth <= 650 ? 0 : overflowY)
            : y,
        left: x,
      }}
    >
      <Link
        to={crm + `/unloading/${marketplaceId}/link/${data.id}`}
        className="link"
      >
        <div className="linkIcon">
          <EditIcon className="h-[12px] w-[12px]" />
        </div>
        <p className="linkText">Редагувати</p>
      </Link>
      <div
        className="link"
        onClick={() => {
          dispatch(
            showModal({
              name: "DeleteLinkModal",
            }),
          );
          dispatch(setSelectUnloading(data.id));
        }}
      >
        <div className="linkIcon">
          <DeleteIcon className="h-[13px] w-[12px]" />
        </div>
        <p className="linkText">Видалити</p>
      </div>
    </div>
  );
}
