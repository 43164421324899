import { ControlPanel, Pagination, TableProducts } from "./components";
import style from "./ProductsManager.module.scss";

export function ProductsManager() {
  return (
    <div className={style["container"]}>
      <ControlPanel />
      <TableProducts />
      <Pagination />
    </div>
  );
}
