import {
  ListVariationsBody,
  ListVariationsHead,
  ListVariationsItem,
} from "./components";
import cn from "classnames";
import style from "./ListVariations.module.scss";

export function ListVariations() {
  return (
    <div className="h-full pb-[30px] max-sm:pb-0 ">
      <ListVariationsHead />
      <div className={cn(style["limit"], "scroll")}>
        <ListVariationsBody>
          {(data) => <ListVariationsItem key={data.id} variation={data} />}
        </ListVariationsBody>
      </div>
    </div>
  );
}
