import { useDispatch } from "react-redux";
import style from "./ProductVariationsWrapper.module.scss";
import { Button, SpoilerButton } from "@ui";
import { useParams } from "react-router-dom";
import { useState } from "react";
import cn from "classnames";
import { showModal } from "@global-state/entity/modal-state/slice";

export function ProductVariationsWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  const [isShow, setIsShow] = useState<boolean>(true);

  const { productId } = useParams();

  const dispatch = useDispatch();

  return (
    <div className={style["variations"]}>
      <div className={style["controll"]}>
        <div
          className={style["flexContainer"]}
          onClick={() => {
            setIsShow((state) => !state);
          }}
        >
          <div className={style["label"]}>Варіації</div>
          <SpoilerButton
            className={style["spoilerButtonCustom"]}
            isOpen={isShow}
          />
        </div>
        <div className={cn(style["containerButton"], isShow && "!block")}>
          {productId ? (
            <Button
              className="px-[35px]"
              type="edit"
              size="big"
              variant="outlined"
              text="Змінити варіації"
              onClick={() => {
                dispatch(
                  showModal({
                    name: "AddVariationsModal",
                  }),
                );
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={cn(style["content"], isShow && "!block")}>{children}</div>
    </div>
  );
}
