import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActions.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";
import cn from "classnames";

export function MultipleActions() {
  const delete_products = useAppSelector(
    (state) => state.productsPage.product.delete,
  );

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn(
        style["multipleActions"],
        delete_products.some((item) => item.tag === "multiple") && "h-[50px]",
      )}
    >
      <div className={style["button"]}>
        <Button
          type="delete"
          size="big"
          text="Видалити"
          onClick={() => {
            dispatch(
              showModal({
                name: "DeleteProductModal",
                mode: "multiple",
              }),
            );
          }}
        />
      </div>
    </div>
  );
}
