import {
  referenceBook_deleteValues_clearData,
  referenceBook_deleteValues_setData,
  referenceBook_editValues_clearData,
  referenceBook_editValues_setData,
} from "@global-state/pages/referenceBook/slice";
import { ReferenceBookValue } from "@interfaces/refence-book";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllValuesCheckbox({
  values,
}: {
  values: ReferenceBookValue[] | undefined;
}) {
  const delete_values = useAppSelector(
    (state) => state.referenceBookPage.delete_values,
  );
  const edit_values = useAppSelector(
    (state) => state.referenceBookPage.edit_values,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        values?.length ===
          delete_values.filter((item) => item.tag === "multiple").length &&
        values?.length ===
          edit_values.filter((item) => item.tag === "multiple").length
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            referenceBook_deleteValues_setData(
              values?.map((value) => ({
                id: value.id,
                tag: "multiple",
              })) || [],
            ),
          );
          dispatch(
            referenceBook_editValues_setData(
              values?.map((value) => ({
                id: value.id,
                name: value.name,
                tag: "multiple",
              })) || [],
            ),
          );
        } else {
          dispatch(referenceBook_deleteValues_clearData());
          dispatch(referenceBook_editValues_clearData());
        }
      }}
    />
  );
}
