import { ChangeEvent, useRef, DragEvent } from "react";
import {
  DndContext,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import Compressor from "compressorjs";
import { useDispatch } from "react-redux";

import { GridContainer } from "./components";

import style from "./ProductImagesModal.module.scss";
import { useAppSelector } from "store/hooks";
import {
  addPicturesModal,
  removePicturesModal,
  setPicuressModal,
} from "@global-state/pages/product/slice";
import { ItemDndImage } from "../common";

export let allFilesModal: Record<string, File> = {};

export function clearAllFilesModal() {
  allFilesModal = {};
}

export function ProductImagesModal() {
  const pictures = useAppSelector((state) => state.productPage.picturesModal);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const dispatch = useDispatch();

  const input = useRef<HTMLInputElement>(null);

  const handleFileInputDrop = async (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files) as File[];

    await addFileModal(newFiles);
  };

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files as FileList) as File[];

    if (input.current) input.current.value = "";

    await addFileModal(newFiles);
  };

  return (
    <div className="scroll mb-[40px] flex justify-between gap-[25px] max-sm:max-h-[300px] max-sm:flex-col max-sm:overflow-auto">
      <label
        htmlFor="filesModal"
        className={style["fileDropZone"]}
        onDragOver={(e) => {
          e.preventDefault();
          e.currentTarget.classList.add(style["dragOver"]);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.currentTarget.classList.remove(style["dragOver"]);
        }}
        onDrop={handleFileInputDrop}
      >
        <div className={style["descr"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
          >
            <path
              opacity="0.5"
              d="M39.5 19.5049C44.9375 19.5352 47.8822 19.7763 49.8032 21.6973C52 23.894 52 27.4295 52 34.5005V37.0005C52 44.0715 52 47.6073 49.8032 49.8038C47.6065 52.0005 44.071 52.0005 37 52.0005H17C9.92893 52.0005 6.3934 52.0005 4.1967 49.8038C2 47.6073 2 44.0715 2 37.0005V34.5005C2 27.4295 2 23.894 4.1967 21.6972C6.11765 19.7763 9.06243 19.5352 14.5 19.5049"
              stroke="#ACACAC"
              strokeWidth="2.75"
              strokeLinecap="round"
            />
            <path
              d="M27 34.5V2M27 2L34.5 10.75M27 2L19.5 10.75"
              stroke="#868686"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>
            Завантажте потрібну <br /> фотографію
          </p>
        </div>
        <input
          ref={input}
          id="filesModal"
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          multiple
          style={{ opacity: 0, visibility: "hidden", width: 0, height: 0 }}
        />
      </label>
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleDragEnd}
      >
        <SortableContext strategy={rectSortingStrategy} items={pictures}>
          <GridContainer columns={3}>
            {pictures.map((item) => (
              <ItemDndImage
                key={item.id}
                data={item}
                onRemove={(id) => {
                  dispatch(removePicturesModal(id));
                }}
                baseStyles="relative w-[78.5px] h-[78.5px] max-sm:w-[150px] max-sm:h-[150px]"
              />
            ))}
          </GridContainer>
        </SortableContext>
      </DndContext>
    </div>
  );

  function handleDragEnd({ active, over }: DragEndEvent) {
    if (!over) {
      return;
    }

    dispatch(
      setPicuressModal(
        arrayMove(
          pictures,
          pictures.indexOf(pictures.filter((item) => item.id === active.id)[0]),
          pictures.indexOf(pictures.filter((item) => item.id === over.id)[0]),
        ),
      ),
    );
  }

  async function addFileModal(files: File[]) {
    const compessImg = await compressedImage(files);

    Promise.all([...compessImg]).then((images) => {
      const newImages: Record<string, File> = {};

      images.forEach((item) => {
        const id = crypto.randomUUID();

        newImages[id] = item;
        allFilesModal[id] = item;
      });

      dispatch(
        addPicturesModal(
          Object.keys(newImages).map((idFile) => ({
            id: idFile,
            url: URL.createObjectURL(newImages[idFile]),
          })),
        ),
      );
    });
  }
}

async function compressedImage(arrImage: File[]) {
  return arrImage.map(async (item) => {
    return await new Promise((resolve: (value: File) => void, reject) => {
      new Compressor(item, {
        quality: 0.5,
        success(result) {
          const newFile = result as File;

          resolve(newFile);
        },
        error(error) {
          reject(error);
        },
      });
    });
  });
}
