import { ProductProvider } from "@interfaces/stock";
import { useLazyProductsProvidersQuery } from "@RTKQuery/stock/api";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";

export function TableProductsProvidersBody({
  sortBy,
  order,
  children,
}: {
  sortBy: string;
  order: string;
  children: (data: ProductProvider) => JSX.Element;
}) {
  const [getProductsProviders, { data }] = useLazyProductsProvidersQuery();

  const activeModal = useAppSelector((state) => state.modalState.modalName);
  const searchText = useAppSelector(
    (state) => state.stockPage.searchTextProductProvider,
  );

  useEffect(() => {
    if (activeModal === "AddProductProviderModal") {
      getProductsProviders(searchText);
    }
  }, [activeModal, searchText]);

  return (
    <>
      {data?.products
        .slice()
        .sort((a, b) => {
          if (sortBy === "provider") {
            return sort(a.supplier, b.supplier, order);
          } else if (sortBy === "name") {
            return sort(a.name, b.name, order);
          } else {
            return 0;
          }
        })
        .map((item) => children(item))}
    </>
  );
}

function sort<T>(a: T, b: T, order: string) {
  if (typeof a === "string" && typeof b === "string") {
    return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  } else if (typeof a === "number" && typeof b === "number") {
    return order === "asc" ? a - b : b - a;
  } else {
    return 0;
  }
}
