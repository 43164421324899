import {
  AllProducts,
  ParentProduct,
  Providers,
  TopPanel,
} from "components/catalog/stock";
import style from "./StockOnePage.module.scss";
import { AddProductProviderModal, DeleteProductProviderModal } from "@modals";
import { useEffect } from "react";
import { clearAllStockData } from "@global-state/pages/stock/slice";
import { useAppDispatch } from "store/hooks";

export function StockOnePage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllStockData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <TopPanel />
      <div className={style["scrollContainer"]}>
        <ParentProduct />
        <div className={style["flexContainer"]}>
          <AllProducts />
          <Providers />
        </div>
      </div>
      <DeleteProductProviderModal />
      <AddProductProviderModal />
    </main>
  );
}
