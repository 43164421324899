export function HelpSvg() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="5.5" stroke="#A0A0A0" />
      <path
        d="M6.6499 7.04443H5.5293C5.53223 6.78076 5.55273 6.55371 5.59082 6.36328C5.63184 6.16992 5.70068 5.99561 5.79736 5.84033C5.89697 5.68506 6.02881 5.53125 6.19287 5.37891C6.33057 5.25586 6.45068 5.13867 6.55322 5.02734C6.65576 4.91602 6.73633 4.80176 6.79492 4.68457C6.85352 4.56445 6.88281 4.43115 6.88281 4.28467C6.88281 4.11475 6.85645 3.97412 6.80371 3.86279C6.75391 3.74854 6.67773 3.66211 6.5752 3.60352C6.47559 3.54492 6.34961 3.51562 6.19727 3.51562C6.07129 3.51562 5.9541 3.54346 5.8457 3.59912C5.7373 3.65186 5.64795 3.73389 5.57764 3.84521C5.51025 3.95654 5.4751 4.10303 5.47217 4.28467H4.19775C4.20654 3.8833 4.29883 3.55225 4.47461 3.2915C4.65332 3.02783 4.89209 2.83301 5.19092 2.70703C5.48975 2.57813 5.8252 2.51367 6.19727 2.51367C6.60742 2.51367 6.95898 2.58105 7.25195 2.71582C7.54492 2.84766 7.76904 3.04248 7.92432 3.30029C8.07959 3.55518 8.15723 3.86572 8.15723 4.23193C8.15723 4.48682 8.10742 4.71387 8.00781 4.91309C7.9082 5.10938 7.77783 5.29248 7.6167 5.4624C7.45557 5.63232 7.27832 5.80811 7.08496 5.98975C6.91797 6.13916 6.80371 6.2959 6.74219 6.45996C6.68359 6.62402 6.65283 6.81885 6.6499 7.04443ZM5.39746 8.40234C5.39746 8.21484 5.46191 8.05957 5.59082 7.93652C5.71973 7.81055 5.89258 7.74756 6.10938 7.74756C6.32324 7.74756 6.49463 7.81055 6.62354 7.93652C6.75537 8.05957 6.82129 8.21484 6.82129 8.40234C6.82129 8.58398 6.75537 8.73779 6.62354 8.86377C6.49463 8.98975 6.32324 9.05273 6.10938 9.05273C5.89258 9.05273 5.71973 8.98975 5.59082 8.86377C5.46191 8.73779 5.39746 8.58398 5.39746 8.40234Z"
        fill="#A0A0A0"
      />
    </svg>
  );
}
