import { RiLoader5Fill } from "react-icons/ri";
import { Button } from "../Button";
import { SaveSvg } from "./Save.svg";

export function SmallSaveButton({
  onClick,
  status = "idle",
}: {
  onClick: () => void;
  status?: "error" | "success" | "idle" | "load";
}) {
  let activeStyle = {};

  if (status === "success") {
    activeStyle = {
      button: "!bg-[#D8FFD1]",
      icon: "!stroke-[#3EAA2D]",
    };
  }

  if (status === "error") {
    activeStyle = {
      button: "!bg-[#FFD8D8]",
      icon: "!stroke-[#D03A3A]",
    };
  }

  return (
    <Button
      type="edit"
      icon={(className) =>
        status === "load" ? (
          <RiLoader5Fill className={"w-[20px] animate-spin"} />
        ) : (
          <SaveSvg className={className} />
        )
      }
      onClick={onClick}
      disabled={status === "load"}
      customStyle={activeStyle}
    />
  );
}
