import { useProductProviderAddMutation } from "@RTKQuery/stock/api";
import { SubmitButton } from "@ui";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

export function SaveButton() {
  const { productId } = useParams();

  const [addProductProvider, addProductProviderStatus] =
    useProductProviderAddMutation({
      fixedCacheKey: "productProvider_add",
    });

  const addProductProviderSelectData = useAppSelector(
    (state) => state.stockPage.addProductProviderSelectData,
  );

  return (
    <SubmitButton
      type="button"
      disabled={addProductProviderStatus.isLoading}
      isUninitialized={addProductProviderStatus.isUninitialized}
      isLoading={addProductProviderStatus.isLoading}
      isSuccess={addProductProviderStatus.isSuccess}
      isError={addProductProviderStatus.isError}
      uninitializedText="Зберегти"
      successText="Успішно збережено"
      errorText="Не вдалось зберегти"
      errorMesage={
        addProductProviderStatus.error
          ? (
              addProductProviderStatus.error as unknown as {
                data: { error: string };
              }
            ).data.error
          : ""
      }
      onClick={() => {
        addProductProvider({
          parent_id: Number(productId),
          ...addProductProviderSelectData,
        });
      }}
      className="mx-auto h-[43px] w-[225px] shrink-0 max-sm:w-full"
    />
  );
}
