import { useState } from "react";

import cn from "classnames";

import style from "./Item.module.scss";
import { TenantDelete } from "@interfaces/tenant";
import { useAppDispatch } from "store/hooks";
import { SubmitButton } from "@ui";
import { axiosAuth } from "@api";
import {
  setDeleteTenant,
  setTenant,
} from "@global-state/entity/superadmin/slice";

export function Item({ data }: { data: TenantDelete }) {
  const [submitingStatus, setSubmitingStatus] = useState<
    "load" | { status: "failure"; errorText: string } | "idle"
  >("idle");

  const dispatch = useAppDispatch();

  return (
    <tr>
      <td className={style["col"]}>
        <p className={style["subdomain"]}>{data.subdomain}</p>
        <div className={style["fullLinkBox"]}>
          <div
            className={cn(
              style["customOverflow"],
              "overflow-gradient",
              "custom-responsive-domain",
            )}
          ></div>
          <div className="custom-responsive-domain overflow-hidden">
            <p className={style["fullLink"]}>{data.subdomain}.levelup.com.ua</p>
          </div>
        </div>
      </td>
      <td className={style["col"]}>
        <div className={style["box"]}>
          <div className={style["deleteTime"]}>
            <span className={style["time"]}>{data.will_be_deleted_in}</span>
          </div>
          <div className={style["buttonBox"]}>
            <SubmitButton
              isUninitialized={submitingStatus === "idle"}
              isLoading={submitingStatus === "load"}
              isSuccess={false}
              isError={typeof submitingStatus === "object"}
              errorText="Помилка"
              successText=""
              uninitializedText="Відмінити"
              type="button"
              className={style["customButton"]}
              errorMesage={
                typeof submitingStatus === "object"
                  ? submitingStatus.errorText
                  : ""
              }
              onClick={() => {
                setSubmitingStatus("load");

                axiosAuth("https://account-api.levelup.com.ua")
                  .post("/account/tenant/cancel_deletion", {
                    uuid: data.uuid,
                  })
                  .then((response) => {
                    dispatch(setTenant(response.data.tenants));
                    dispatch(setDeleteTenant(response.data.deleted_tenants));
                  })
                  .catch((err) => {
                    setSubmitingStatus({
                      status: "failure",
                      errorText: err?.response?.data?.error || "Error",
                    });
                  });
              }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
