import { EditBookForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  referenceBook_deleteBooks_clearData,
  referenceBook_deleteBooks_deleteData,
  referenceBook_editBooks_clearData,
  referenceBook_editBooks_deleteData,
} from "@global-state/pages/referenceBook/slice";

import { useReferenceBookUpdateMutation } from "@RTKQuery/reference-book/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "EditBookModal";

export function EditBookModal() {
  const [_, { isSuccess, reset }] = useReferenceBookUpdateMutation({
    fixedCacheKey: "edit-book",
  });

  const { modalName: activeModal, mode: activeMode } = useAppSelector(
    (state) => state.modalState,
  );

  const listItems = useAppSelector(
    (state) => state.referenceBookPage.edit_books,
  );
  let singleId: number =
    listItems.find((item) => item.tag === "single")?.id || -1;

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal) {
        if (activeMode === "single") {
          dispatch(
            referenceBook_deleteBooks_deleteData({
              id: singleId,
              singleOnly: false,
            }),
          );
          dispatch(
            referenceBook_editBooks_deleteData({
              id: singleId,
              singleOnly: false,
            }),
          );
        } else {
          dispatch(referenceBook_deleteBooks_clearData());
          dispatch(referenceBook_editBooks_clearData());
        }
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess && activeMode === "single") {
      dispatch(
        referenceBook_editBooks_deleteData({
          id: singleId,
          singleOnly: true,
        }),
      );
    }
    dispatch(hideModal());
  }

  return (
    <CRMModalLayout
      isShow={activeModal === MODAL_NAME}
      hiddenModal={hiddenModal}
      title={
        activeMode === "single"
          ? "Відредагувати довідник"
          : "Відредагувати довідники"
      }
    >
      <EditBookForm />
    </CRMModalLayout>
  );
}
