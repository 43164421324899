import { ProductButtons } from "./components";
import cn from "classnames";
import style from "./TableProductItem.module.scss";
import { ProductTable } from "@interfaces/product";

export function TableProductItem({ data }: { data: ProductTable }) {
  return (
    <div className={style["item"]}>
      <div className={cn(style["bodyColumn"])} title={data.sku}>
        <p className="max-w-full truncate">{data.sku}</p>
      </div>
      <div className={cn(style["bodyColumn"])} title={data.ua_name}>
        <p className="max-w-full truncate">{data.ua_name}</p>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.buying_price)}
      >
        <p className="max-w-full truncate">{data.buying_price} грн.</p>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.retail_price)}
      >
        <p className="max-w-full truncate">{data.retail_price} грн.</p>
      </div>
      <div className={style["bodyColumn"]}>
        <div className={style["imageLimit"]}>
          <img src={data.picture} alt={data.ua_name} className={style["img"]} />
        </div>
      </div>
      <div className={cn(style["bodyColumn"], "px-0")}>
        <ProductButtons product={data} />
      </div>
    </div>
  );
}
