import {
  referenceBook_deleteBooks_clearData,
  referenceBook_deleteBooks_setData,
  referenceBook_editBooks_clearData,
  referenceBook_editBooks_setData,
} from "@global-state/pages/referenceBook/slice";
import ReferenceBookGroup from "@interfaces/refence-book";
import { useReferenceBookAllQuery } from "@RTKQuery/reference-book/api";
import { Checkbox } from "@ui";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllBooksCheckbox() {
  const { group } = useParams();

  const { data } = useReferenceBookAllQuery();

  const groupItem: ReferenceBookGroup | undefined = data?.find((item) => {
    return item.id === Number(group || "-1");
  });

  const delete_books = useAppSelector(
    (state) => state.referenceBookPage.delete_books,
  );
  const edit_books = useAppSelector(
    (state) => state.referenceBookPage.edit_books,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        groupItem?.items.length ===
          delete_books.filter((item) => item.tag === "multiple").length &&
        groupItem?.items.length ===
          edit_books.filter((item) => item.tag === "multiple").length
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            referenceBook_deleteBooks_setData(
              groupItem?.items.map((book) => ({
                id: book.id,
                tag: "multiple",
              })) || [],
            ),
          );
          dispatch(
            referenceBook_editBooks_setData(
              groupItem?.items.map((book) => ({
                id: book.id,
                name: book.name,
                group_id: groupItem.id,
                tag: "multiple",
              })) || [],
            ),
          );
        } else {
          dispatch(referenceBook_deleteBooks_clearData());
          dispatch(referenceBook_editBooks_clearData());
        }
      }}
    />
  );
}
