import { AllCharacteristicsCheckbox } from "./components";
import style from "./ListCharacteristicsHead.module.scss";

export function ListCharacteristicsHead() {
  return (
    <div className={style["listHeader"]}>
      <AllCharacteristicsCheckbox />
      <div className={style["label"]}>Характеристики</div>
    </div>
  );
}
