import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import superadmin from "../slices/entity/superadmin/slice";
import CRMUser from "../slices/entity/CRMUser/slice";
import modalState from "../slices/entity/modal-state/slice";
import balancePage from "../slices/pages/balance/slice";
import productPage from "../slices/pages/product/slice";
import unloadingPage from "../slices/pages/unloading/slice";
import referenceBookPage from "../slices/pages/referenceBook/slice";
import productsTemplatePage from "../slices/pages/productsTemplate/slice";
import tenantPage from "../slices/pages/tenant/slice";
import productsPage from "../slices/pages/products/slice";
import stockPage from "../slices/pages/stock/slice";
import { api } from "../querySlices";

export const store = configureStore({
  reducer: {
    superadmin,
    CRMUser,
    modalState,
    balancePage,
    productPage,
    unloadingPage,
    referenceBookPage,
    productsTemplatePage,
    tenantPage,
    productsPage,
    stockPage,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type SliceAction<T> = {
  payload: T;
  type: string;
};
export type PartialExcept<T, K extends keyof T = keyof T> = Partial<
  Omit<T, K>
> &
  Pick<T, K>;
