import { showModal } from "@global-state/entity/modal-state/slice";
import { category_add_setData } from "@global-state/pages/products/slice";
import { useCategoriesAllQuery } from "@RTKQuery/categories/api";
import { Button } from "@ui";
import { useAppDispatch } from "store/hooks";
import style from "./MenuCategoriesAddButton.module.scss";

export function MenuCategoriesAddButton() {
  const { data } = useCategoriesAllQuery();

  const dispatch = useAppDispatch();

  return (
    <div className={style["button"]}>
      {data?.length === 0 ? (
        <Button
          type="add"
          size="big"
          variant="outlined"
          text="Створіть першу категорію"
          onClick={() => {
            dispatch(category_add_setData(null));
            dispatch(
              showModal({
                name: "AddCategoryModal",
              }),
            );
          }}
        />
      ) : (
        <Button
          type="add"
          size="big"
          variant="outlined"
          onClick={() => {
            dispatch(category_add_setData(null));
            dispatch(
              showModal({
                name: "AddCategoryModal",
              }),
            );
          }}
        />
      )}
    </div>
  );
}
