import {
  ListCharacteristicsItem,
  ListCharacteristicsHead,
  ListCharacteristicsBody,
} from "./components";
import cn from "classnames";
import style from "./ListCharacteristics.module.scss";

export function ListCharacteristics() {
  return (
    <div className="h-full pb-[30px] max-sm:pb-0">
      <ListCharacteristicsHead />
      <div className={cn(style["limit"], "scroll")}>
        <ListCharacteristicsBody>
          {(data) => (
            <ListCharacteristicsItem key={data.id} characteristic={data} />
          )}
        </ListCharacteristicsBody>
      </div>
    </div>
  );
}
