import { ProductChildren, ProductExtended } from "@interfaces/product";
import { api } from "..";
import { ProductStock, ConnectedProduct, ProductProvider } from "@interfaces/stock";

const stockApi = api.injectEndpoints({
    endpoints: (build) => ({
        stockUpdate: build.mutation<ProductChildren<ProductStock>, Pick<ProductStock, "id" | "qty"> & { parentId: number }>({
            query: ({ id, qty }) => {
                return {
                    url: "stock/" + id,
                    method: "PUT",
                    body: { qty }
                }
            },
            async onQueryStarted({ parentId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        api.util.upsertQueryData(
                            "productStockOne",
                            parentId,
                            data
                        ),
                    );
                } catch { }
            },
        }),
        productProviderDelete: build.mutation<void, {
            productId: ProductStock["id"]
            providerId: ConnectedProduct["supplier"]["id"]
        }>({
            query: (data) => {
                return {
                    url: "stock",
                    method: "DELETE",
                    body: data
                }
            }
        }),
        productProviderAdd: build.mutation<{
            stock: ProductChildren<ProductStock>,
            easydrop: ConnectedProduct[]
        }, {
            supplier_id: ProductProvider["supplier_id"],
            product_id: ProductProvider["id"],
            parent_id: ProductExtended["id"]
        }>({
            query: ({ supplier_id, product_id, parent_id }) => {
                return {
                    url: `stock/${parent_id}/easydrop`,
                    method: "POST",
                    body: { supplier_id, product_id }
                }
            },
            async onQueryStarted({ parent_id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        api.util.upsertQueryData(
                            "productStockOne",
                            parent_id,
                            data.stock
                        ),
                    );
                    dispatch(
                        api.util.upsertQueryData(
                            "productStockOneConnectedProducts",
                            parent_id,
                            data.easydrop
                        ),
                    );
                } catch { }
            },
        })
    }),
    overrideExisting: false,
});

export const {
    useProductStockOneQuery,
    useProductStockOneConnectedProductsQuery,
    useLazyProductsProvidersQuery,
    useStockUpdateMutation,
    useProductProviderAddMutation,
    useProductProviderDeleteMutation
} = stockApi;